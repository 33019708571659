import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import {
  feedMenuModalState,
  feedPostModalState,
  twoButtonModalState,
} from "../../utils/atom";
import { api } from "../../utils/api";

import { ReactComponent as Menu } from "../../assets/icons/side-menu-icon.svg";

const MenuButton = ({ id, mutate }: { id: number; mutate: () => void }) => {
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setPostModal = useSetRecoilState(feedPostModalState);
  const setMenuModal = useSetRecoilState(feedMenuModalState);

  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = async () => {
    const {
      data: { success, message, data },
    } = await api.delete(`/feed/delete?feedId=${id}`);

    if (success) {
      mutate();
    } else {
      window.alert(message);
    }
  };

  return (
    <Wrapper>
      <Button
        type="button"
        onClick={() => {
          if (window.outerWidth > 1024) setOpen(!open);
          else
            setMenuModal({
              onEdit: () => setPostModal({ edit: true, id: id }),
              onDelete: () =>
                setTwoButtonModal({
                  title: "피드 삭제",
                  text: "피드를 삭제하시겠습니까?<br/>삭제된 피드는 복구하실 수 없습니다.",
                  confirmText: "삭제",
                  cancleText: "취소",
                  onConfirm: handleDelete,
                }),
            });
        }}
      >
        <Menu />
      </Button>
      <OptionWrapper className={open ? "active" : ""}>
        <OptionList>
          <Option
            className="select"
            onClick={() => {
              setOpen(false);
              setPostModal({ edit: true, id: id });
            }}
          >
            피드 수정
          </Option>
          <Option
            onClick={() => {
              setOpen(false);
              setTwoButtonModal({
                title: "피드 삭제",
                text: "피드를 삭제하시겠습니까?<br/>삭제된 피드는 복구하실 수 없습니다.",
                confirmText: "삭제",
                cancleText: "취소",
                onConfirm: handleDelete,
              });
            }}
          >
            피드 삭제
          </Option>
        </OptionList>
      </OptionWrapper>
    </Wrapper>
  );
};

export default MenuButton;

const Option = styled.li`
  width: 100%;
  height: 34px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;
`;

const OptionList = styled.ul`
  list-style-type: none;
  padding: 6px 0;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray6};
  background: #fff;
  max-height: 216px;
`;

const OptionWrapper = styled.div`
  position: absolute;
  top: 34px;
  right: 0;
  width: 120px;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s;
  z-index: 1;

  &.active {
    max-height: 216px;
    overflow: auto;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  margin-left: 6px;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.gray7};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    width: 28px;
    height: 28px;

    & > svg {
      width: 20px;
      height: 20px;

      & circle {
        fill: #1e1e1e;
      }
    }
  }
`;

const Wrapper = styled.span`
  width: 30px;
  display: block;
  position: relative;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 28px;
  }
`;
