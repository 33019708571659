import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { twoButtonModalState, vcardPostModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import Textarea from "../elements/Textarea";
import Input from "../elements/Input";
import ImageUpload from "../elements/ImageUpload";
import Select from "../elements/Select";
import ToggleButton from "../elements/ToggleButton";
import UserTagSelect from "../elements/UserTagSelect";
import Button from "../elements/Button";
import { api } from "../../utils/api";
import { OptionType } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Dot, toNumber } from "../../utils/CommonFunction";
import TimePickerInput from "../elements/TimePickerInput";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { OtherCardType } from "../../types/api";

const VCardPostModal = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [modal, setModal] = useRecoilState(vcardPostModalState);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const [content, setContent] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [image, setImage] = useState<{ id: number; image: string }>();
  const [tagUser, setTagUser] = useState<OptionType>();
  const [reservation, setReservation] = useState<boolean>(false);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [manager, setManager] = useState<boolean>(false);
  const [publicStatus, setPublicStatus] = useState<OptionType>();
  const [point, setPoint] = useState<string>("");
  const [pointError, setPointError] = useState<string>("");
  const [select, setSelect] = useState<boolean>(true);

  const { data: ImageData } = useSWR(
    modal !== null && `/vcard/other?vcardId=${modal?.cardId}`
  );
  const { data: pointData } = useSWR(`/available/point`);
  const { data: profile } = useSWR(modal !== null && `/profile`);

  useEffect(() => {
    if (modal !== null) {
      setImage({ id: modal?.cardId, image: modal?.cardThumb });
      if ((modal?.id || modal?.user) && select) {
        setTagUser({ value: String(modal?.id), text: modal?.user });
      }
    }
  }, [modal, select]);

  const handleClose = () => {
    setModal(null);
    setContent("");
    setTags("");
    setPoint("");
    setImage(undefined);
    setTagUser(undefined);
    setReservation(false);
    setManager(false);
    setPublicStatus(undefined);
    setSelect(true);
  };

  const handleSubmit = async () => {
    const formData = JSON.stringify({
      vcardId: image?.id,
      publicStatus: publicStatus?.value,
      userId: tagUser?.value,
      point: Number(point) > 0 ? point : "0",
      tags: tags,
      contents: content,
      isReservation: reservation ? 1 : 0,
      reservationDate: reservation ? date.format("YYYY-MM-DD HH:mm:00") : "",
      referManager: manager ? 1 : 0,
    });

    const {
      data: { success, message, data },
    } = await api.post("/vcard/register", formData);

    if (success) {
      handleClose();
      window.location.reload();
    } else {
      window.alert(message);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="full">
      <Wrapper>
        <Title>
          V-CARD 작성
          <Close onClick={handleClose} />
        </Title>
        <Content>
          <VCardPreview>
            <Preview
              src={
                image
                  ? image?.image
                  : require("../../assets/images/preview-image.png")
              }
            />
            <CardList>
              {ImageData?.map((item: OtherCardType, i: number) => (
                <div
                  key={"vcard_list_" + i}
                  className={image?.id === item.id ? "active" : ""}
                  onClick={() =>
                    setImage({ id: item?.id, image: item?.thumbnailImage })
                  }
                  style={{
                    background: `url(${item.thumbnailImage}) no-repeat center/cover`,
                  }}
                />
              ))}
            </CardList>
            <CardSwiper
              //@ts-ignore
              slidesPerView={3.5}
              spaceBetween={10}
            >
              {ImageData?.vcardImages?.map((item: OtherCardType, i: number) => (
                <SwiperSlide
                  key={"vcard_list_" + i}
                  onClick={() =>
                    setImage({ id: item?.id, image: item?.thumbnailImage })
                  }
                >
                  <div
                    className={image?.id === item.id ? "active" : ""}
                    style={{
                      background: `url(${item.thumbnailImage}) no-repeat center/cover`,
                    }}
                  />
                </SwiperSlide>
              ))}
            </CardSwiper>
          </VCardPreview>
          <AnyCard
            type="button"
            onClick={() =>
              setTwoButtonModal({
                title: "V-CARD 작성 취소",
                text: "V-CARD 작성을 취소하시겠어요?<br/>취소하시면 작성된 내용은 삭제됩니다.",
                confirmText: "취소",
                cancleText: "뒤로",
                onConfirm: () => {
                  handleClose();
                  navigate(`/card`);
                },
              })
            }
          >
            다른 V-CARD 선택하기
          </AnyCard>
          <Textarea
            placeholder={`${
              profile?.name ? profile?.name : ""
            } 님, 어떤 내용을 작성하시겠어요?`}
            value={content}
            onChange={(e) => setContent(e.target.value.slice(0, 1000))}
            max={1000}
          />
          <Input
            label="해시태그"
            placeholder="해시태그 입력 (쉼표로 구분)"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
          <FlexBox>
            <UserTagSelect
              label="인정 대상"
              placeholder="대상 검색"
              value={tagUser}
              onChange={(value) => setTagUser(value)}
              handleClick={() => {
                setSelect(false);
              }}
            />
            <Select
              label="공개범위"
              placeholder="공개범위"
              keyValue="public_"
              value={publicStatus}
              options={[
                { value: "1", text: "전체 공개" },
                { value: "0", text: "V-CARD만 공개" },
              ]}
              onChange={(value) => setPublicStatus(value)}
            />
          </FlexBox>
          <Input
            label="인정 포인트"
            placeholder="선물할 포인트를 입력하세요"
            value={Dot(Number(point))}
            errorText={pointError}
            onChange={(e) => {
              let value = toNumber(e.target.value);
              if (value > pointData?.point)
                setPointError("선물 가능 포인트를 초과했습니다.");
              else setPointError("");
              setPoint(value > pointData?.point ? pointData?.point : value);
            }}
            viva={pointData?.point}
          />
          <ToggleArea>
            <ToggleBox onClick={() => setReservation(!reservation)}>
              예약 발송
              <ToggleButton checked={reservation} onClick={() => {}} />
            </ToggleBox>
            <ToggleBox onClick={() => setManager(!manager)}>
              매니저 참조
              <ToggleButton checked={manager} onClick={() => {}} />
            </ToggleBox>
          </ToggleArea>
          {reservation && (
            <TimePickerInput
              label="발송일시"
              date={date}
              setDate={setDate}
              format="YYYY.MM.DD HH:mm"
            />
          )}
        </Content>
        <ButtonArea>
          <Button
            onClick={() =>
              setTwoButtonModal({
                title: "V-CARD 발송",
                text: "V-CARD를 대상자에게 발송하시겠습니까?",
                confirmText: "발송",
                cancleText: "취소",
                onConfirm: handleSubmit,
              })
            }
            disabled={
              image === undefined ||
              content === "" ||
              !publicStatus ||
              tagUser === undefined ||
              point === undefined ||
              (reservation && date === undefined)
            }
          >
            V-CARD 발송
          </Button>
          <Button
            onClick={() =>
              setTwoButtonModal({
                title: "V-CARD 작성 취소",
                text: "V-CARD 작성을 취소하시겠어요?<br/>취소하시면 작성된 내용은 삭제됩니다.",
                confirmText: "취소",
                cancleText: "뒤로",
                onConfirm: handleClose,
              })
            }
            styleType="line"
          >
            취소
          </Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default VCardPostModal;

const CardSwiper = styled(Swiper)`
  width: calc(100% + 40px);
  left: -20px;
  padding: 0 20px 52px;
  margin-top: 10px;
  display: none;

  & .swiper-slide > div {
    width: 100%;
    padding-top: 81.25%;
    border: 2px solid white;

    &.active {
      border-color: ${(props) => props.theme.color.gray1};
    }
  }

  @media (max-width: 1024px) {
    display: block;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 740px;
  width: 100%;
  margin: auto;

  & > button {
    max-width: calc(50% - 10px);
  }

  @media (max-width: 1024px) {
    padding: 20px;
    max-width: 100%;

    & > button {
      max-width: calc(50% - 5px);
    }
  }
`;

const AnyCard = styled.button`
  display: block;
  margin: 13px 0 13px auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-decoration-line: underline;
  color: ${(props) => props.theme.color.gray5};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const CardList = styled.div`
  width: calc(100% - 456px);
  height: 344px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  & > div {
    position: relative;
    width: calc(33.33% - 8px);
    height: 115px;
    margin-right: 12px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid white;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: 12px;
    }

    &.active {
      border-color: ${(props) => props.theme.color.gray1};
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Preview = styled.img`
  width: 420px;
  height: 344px;
  margin-right: 36px;
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 1024px) {
    max-width: 320px;
    width: 100%;
    height: 256px;
    margin: 0 auto;
    display: block;
  }
`;

const VCardPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 344px;

  @media (max-width: 1024px) {
    display: block;
    height: 256px;
  }
`;

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-right: 40px;

  & > div {
    margin-left: 20px;
  }

  @media (max-width: 1024px) {
    margin: 20px 0 0;

    &:first-of-type {
      margin-top: 0;
    }

    & > div {
      margin-left: auto;
    }
  }
`;

const ToggleArea = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > label {
    max-width: calc(50% - 10px);
  }

  @media (max-width: 1024px) {
    display: block;

    & > label {
      max-width: 100%;

      &:nth-of-type(n + 2) {
        display: block;
        margin-top: 20px;
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 30px 0 50px;

  & > label:nth-of-type(n + 2) {
    margin-top: 30px;
    display: block;
  }

  & > div:nth-of-type(n + 2) {
    margin-top: 30px;
  }

  @media (max-width: 1024px) {
    height: calc(100% - 54px - 90px);
    overflow: auto;
    margin: 0;
    padding: 20px 20px 30px;

    & > label:nth-of-type(n + 2) {
      margin-top: 20px;
    }

    & > div:nth-of-type(n + 2) {
      margin-top: 20px;
    }
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
  }
`;
