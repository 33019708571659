import React from "react";
import styled from "styled-components";

const ToggleButton = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <Wrapper className={checked ? "active" : ""} onClick={onClick}>
      <Circle />
    </Wrapper>
  );
};

export default ToggleButton;

const Circle = styled.div`
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 30px;
  background: white;
  box-shadow: 0px 0px 1.9px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;

  .active & {
    left: calc(100% - 17px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 38px;
  height: 20px;
  border-radius: 30px;
  background: ${(props) => props.theme.color.gray5};
  transition: all 0.3s;
  cursor: pointer;

  &.active {
    background: ${(props) => props.theme.color.sub};
  }
`;
