import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));

// 비밀번호 찾기
export const Find = loadable(() => import("./user/Find"));
export const FindResult = loadable(() => import("./user/FindResult"));
export const PasswordChange = loadable(() => import("./user/PasswordChange"));

// 홈
export const Home = loadable(() => import("./Home"));
export const HomeFeed = loadable(() => import("./Feed"));

//자주 묻는 질문
export const Faq = loadable(() => import("./Faq"));

// 챌린지
export const Challenge = loadable(() => import("./Challenge"));

//v-card
export const VCard = loadable(() => import("./vcard/VCard"));
export const Receive = loadable(() => import("./vcard/Receive"));
export const Send = loadable(() => import("./vcard/Send"));

// 랭킹
export const RankSend = loadable(() => import("./rank/Send"));
export const RankReceive = loadable(() => import("./rank/Receive"));

// 내 프로필
export const Mypage = loadable(() => import("./mypage/Mypage"));
export const Active = loadable(() => import("./mypage/Active"));
export const Point = loadable(() => import("./mypage/Point"));

// 검색
export const Search = loadable(() => import("./search/Search"));
export const User = loadable(() => import("./search/User"));
export const Feed = loadable(() => import("./search/Feed"));

//알림
export const Alarm = loadable(() => import("./Alarm"));

//404
export const Error = loadable(() => import("./Error"));
