import React from "react";
import styled from "styled-components";
import { LikeListType } from "../../types/api";

import DefaultProfile from "../../assets/images/proflie-image.svg";

const LikeList = ({ item }: { item: LikeListType }) => {
  return (
    <Wrapper>
      <img
        src={item?.userProfileImage ? item?.userProfileImage : DefaultProfile}
        alt=""
      />
      <Name>{item?.userName}</Name>
      <Department>{item?.userDepartment}</Department>
    </Wrapper>
  );
};

export default LikeList;

const Department = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.gray8};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  white-space: nowrap;
`;

const Name = styled.p`
  width: 100%;
  margin: 0 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > img {
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    object-fit: cover;
  }
`;
