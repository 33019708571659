import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  detailModalState,
  userModalState,
  vcardPostModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import useInfiniteScroll from "../../utils/useInfiniteScroll";
import useSWRInfinite from "swr/infinite";
import { api } from "../../utils/api";
import moment from "moment";
import MenuButton from "../elements/MenuButton";
import { handleNumber, mergeList } from "../../utils/CommonFunction";
import CommentList from "../list/CommentList";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Down } from "../../assets/icons/black-down-arrow-icon.svg";
import { ReactComponent as LikesAct } from "../../assets/icons/likes-active-icon.svg";
import { ReactComponent as Likes } from "../../assets/icons/likes-icon.svg";
import { ReactComponent as LikeAct } from "../../assets/icons/like-active-icon.svg";
import { ReactComponent as Like } from "../../assets/icons/like-icon.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment-icon.svg";
import { ReactComponent as TogetherIcon } from "../../assets/icons/together-icon.svg";

import DefaultProfile from "../../assets/images/proflie-image.svg";

const DetailModal = () => {
  const [modal, setModal] = useRecoilState(detailModalState);

  const navigate = useNavigate();

  const setWithModal = useSetRecoilState(vcardPostModalState);
  const setUserModal = useSetRecoilState(userModalState);

  const [more, setMore] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [edit, setEdit] = useState<number>();
  const [comment, setComment] = useState<string>("");

  const [listData, setListData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const [pc, setPc] = useState(true);

  const ref = useRef(null);
  const content = useRef(null);

  const textRef = useRef(null);

  const { data, mutate } = useSWR(
    modal !== null &&
      (modal?.type === "feed"
        ? `/feed/detail?feedId=${modal?.id}`
        : `/vcard/detail?vcardId=${modal?.id}`)
  );

  const [intersecting, loadFinished, setLoadFinished] = useInfiniteScroll(ref);

  const getKey = (pageIndex: number, previousPageData: any[] | null) => {
    if (previousPageData && !previousPageData.length) {
      //@ts-ignore
      setLoadFinished(true);
      return null;
    }

    if (modal === null) return null;

    return `/comment?page=${pageIndex + 1}&targetType=${modal?.type}&targetId=${
      modal?.id
    }`;
  };

  const fetcher = async (url: string) => {
    const res = await api.get(url);
    setPage(res?.data?.data?.pageInfo?.totalPages);
    setTotal(res?.data?.data?.pageInfo?.totalElements);

    return res.data?.data?.comments;
  };

  //@ts-ignore
  const {
    data: commentData,
    mutate: mutate2,
    size,
    setSize,
  } = useSWRInfinite(getKey, fetcher);

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  useEffect(() => {
    if (commentData?.length) {
      setListData(mergeList(commentData));
    }
  }, [commentData]);

  useEffect(() => {
    if (data && !more && textRef.current !== null) {
      const el: any = textRef.current;

      setMore(el.scrollHeight > 111 && el.offsetHeight < el.scrollHeight);
    }
  }, [data, textRef]);

  const infiniteScroll = (e: React.UIEvent<HTMLElement>) => {
    //@ts-ignore
    let scrollTop = e.target.scrollTop;

    if (!loadFinished && page > size && listData?.length >= size * 10) {
      //@ts-ignore
      const scrollHeight = e.target.scrollHeight;
      //@ts-ignore
      const clientHeight = e.target.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - scrollTop) {
        setSize((prev: number) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (content.current !== null) {
      //@ts-ignore
      let el: any = content.current.parentNode;

      el.addEventListener("scroll", infiniteScroll, true);

      return () => {
        try {
          el.removeEventListener("scroll", infiniteScroll, true);
        } catch (error) {}
      };
    }
  }, [content, listData, page, size, loadFinished]);

  const handleClose = () => {
    setMore(false);
    setShow(false);

    setEdit(undefined);
    setComment("");

    setListData([]);
    setPage(0);
    setTotal(0);

    setPc(true);
    setModal(null);
  };

  const handleLike = async () => {
    const formData = JSON.stringify({
      targetType: modal?.type,
      targetId: modal?.id,
    });

    const {
      data: { success, message, data },
    } = await api.post("/like", formData);

    if (success) {
      mutate();
    } else {
      window.alert(message);
    }
  };

  const handleComment = async () => {
    let contents = comment;
    setComment("");

    const formData = JSON.stringify({
      targetType: modal?.type,
      targetId: modal?.id,
      contents: contents,
    });

    const {
      data: { success, message, data },
    } = await api.post("/comment/register", formData);

    if (success) {
      mutate2();
      modal?.mutate && modal?.mutate();
    } else {
      window.alert(message);
    }
  };

  const handleEdit = async () => {
    let contents = comment;
    setComment("");
    setEdit(undefined);

    const formData = JSON.stringify({
      commentId: edit,
      contents: contents,
    });

    const {
      data: { success, message, data },
    } = await api.post("/comment/modify", formData);

    if (success) {
      mutate2();
    } else {
      window.alert(message);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper ref={content}>
        <Title>
          {!pc && "피드 상세"}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          <FeedContent
            className={modal?.type + " " + (data?.feed?.image ? "image" : "")}
          >
            <ProfileArea className="mobile">
              <div
                onClick={() =>
                  setUserModal({
                    id:
                      modal?.type === "feed"
                        ? data?.feed?.userId
                        : data?.sendUserId,
                  })
                }
              >
                <Profile>
                  <img
                    src={
                      modal?.type === "feed"
                        ? data?.feed?.userProfileImage
                          ? data?.feed?.userProfileImage
                          : DefaultProfile
                        : data?.sendUserProfileImage
                        ? data?.sendUserProfileImage
                        : DefaultProfile
                    }
                    alt=""
                    className="profile"
                  />
                  {(data?.feed?.badgeImage || data?.sendUserBadgeImage) && (
                    <img
                      src={
                        modal?.type === "feed"
                          ? data?.feed?.badgeImage
                          : data?.sendUserBadgeImage
                      }
                      alt=""
                      className="tag"
                    />
                  )}
                </Profile>
                <Name>
                  <h1>
                    {modal?.type === "feed"
                      ? data?.feed?.userName
                      : data?.sendUserName}
                  </h1>
                  <p>
                    {moment(
                      modal?.type === "feed"
                        ? data?.feed?.createdAt
                        : data?.createdAt
                    ).format("YYYY.MM.DD HH:mm")}
                  </p>
                </Name>
                {(data?.feed?.userDepartment || data?.sendUserDepartment) && (
                  <Department>
                    {modal?.type === "feed"
                      ? data?.feed?.userDepartment
                      : data?.sendUserDepartment}
                  </Department>
                )}
              </div>
              {modal?.type === "feed" &&
                data?.feed?.userId === modal?.userId && (
                  <MenuButton
                    id={modal?.id}
                    mutate={() => {
                      mutate();
                      handleClose();
                      modal?.mutate && modal?.mutate();
                    }}
                  />
                )}
            </ProfileArea>
            {modal?.type === "vcard" ? (
              <Vcard>
                <CardImage>
                  <img src={data?.vcardImage} />
                </CardImage>
                <ToBox
                  onClick={() =>
                    setUserModal({
                      id: data?.receiveUserId,
                    })
                  }
                >
                  To.
                  <img
                    src={
                      data?.receiveUserProfileImage
                        ? data?.receiveUserProfileImage
                        : DefaultProfile
                    }
                    alt=""
                  />
                  {data?.receiveUserName}
                  {data?.receiveUserDepartment && (
                    <ReceiveDepart>{data?.receiveUserDepartment}</ReceiveDepart>
                  )}
                </ToBox>
              </Vcard>
            ) : (
              data?.feed?.image && (
                <Image>
                  <div>
                    <img src={data?.feed?.image} alt="" />
                  </div>
                </Image>
              )
            )}
            <MainContent>
              <div>
                <ProfileArea>
                  <div
                    onClick={() =>
                      setUserModal({
                        id:
                          modal?.type === "feed"
                            ? data?.feed?.userId
                            : data?.sendUserId,
                      })
                    }
                  >
                    <Profile>
                      <img
                        src={
                          modal?.type === "feed"
                            ? data?.feed?.userProfileImage
                              ? data?.feed?.userProfileImage
                              : DefaultProfile
                            : data?.sendUserProfileImage
                            ? data?.sendUserProfileImage
                            : DefaultProfile
                        }
                        alt=""
                        className="profile"
                      />
                      {(data?.feed?.badgeImage || data?.sendUserBadgeImage) && (
                        <img
                          src={
                            modal?.type === "feed"
                              ? data?.feed?.badgeImage
                              : data?.sendUserBadgeImage
                          }
                          alt=""
                          className="tag"
                        />
                      )}
                    </Profile>
                    <Name>
                      <h1>
                        {modal?.type === "feed"
                          ? data?.feed?.userName
                          : data?.sendUserName}
                      </h1>
                      <p>
                        {moment(
                          modal?.type === "feed"
                            ? data?.feed?.createdAt
                            : data?.createdAt
                        ).format("YYYY.MM.DD HH:mm")}
                      </p>
                    </Name>
                    {(data?.feed?.userDepartment ||
                      data?.sendUserDepartment) && (
                      <Department>
                        {modal?.type === "feed"
                          ? data?.feed?.userDepartment
                          : data?.sendUserDepartment}
                      </Department>
                    )}
                  </div>
                  {modal?.type === "feed" &&
                    data?.feed?.userId === modal?.userId && (
                      <MenuButton
                        id={modal?.id}
                        mutate={() => {
                          mutate();
                          handleClose();
                          window.location.reload();
                        }}
                      />
                    )}
                </ProfileArea>
                <ContentText
                  ref={textRef}
                  className={show ? "show" : ""}
                  dangerouslySetInnerHTML={{
                    __html:
                      modal?.type === "feed"
                        ? data?.feed?.contents
                        : data?.contents,
                  }}
                />
                {more && !show && (
                  <MoreButton type="button" onClick={() => setShow(true)}>
                    더보기
                    <Down />
                  </MoreButton>
                )}
                {modal?.type === "feed" && (
                  <>
                    {data?.feed?.tagUserId && (
                      <TagUser
                        onClick={() =>
                          setUserModal({
                            id: data?.feed?.tagUserId,
                          })
                        }
                      >
                        <div>
                          <img
                            className="profile"
                            src={
                              data?.tagUser?.profileImage
                                ? data?.tagUser?.profileImage
                                : DefaultProfile
                            }
                            alt=""
                          />
                          <img
                            className="tag"
                            src={data?.tagUser?.profileImage}
                            alt=""
                          />
                        </div>
                        <b>{data?.tagUser?.name}</b>
                        {data?.tagUser?.department && data?.tagUser?.department}
                      </TagUser>
                    )}
                    {data?.feed?.tags && data?.feed?.tags !== "" && (
                      <Tags>
                        {data?.feed?.tags
                          ?.split(",")
                          ?.map((tag: string, i: number) => (
                            <span
                              key={"tag_" + i}
                              onClick={() => {
                                navigate(`/search/all/${tag}`);
                                handleClose();
                              }}
                            >
                              #{tag}&nbsp;
                            </span>
                          ))}
                      </Tags>
                    )}
                  </>
                )}
              </div>
              <CountArea>
                <LikeButton
                  className={
                    (modal?.type === "feed"
                      ? data?.feed?.myLike
                      : data?.myLike) === 1
                      ? "active"
                      : ""
                  }
                >
                  {(modal?.type === "feed"
                    ? data?.feed?.likeCount
                    : data?.likeCount) > 1 ? (
                    (modal?.type === "feed"
                      ? data?.feed?.myLike
                      : data?.myLike) === 1 ? (
                      <LikesAct onClick={handleLike} />
                    ) : (
                      <Likes onClick={handleLike} />
                    )
                  ) : (modal?.type === "feed"
                      ? data?.feed?.myLike
                      : data?.myLike) === 1 ? (
                    <LikeAct onClick={handleLike} />
                  ) : (
                    <Like onClick={handleLike} />
                  )}
                  <b onClick={handleLike}>좋아요</b>
                  <span>
                    {handleNumber(
                      modal?.type === "feed"
                        ? data?.feed?.likeCount
                        : data?.likeCount
                    )}
                  </span>
                </LikeButton>
                <LikeButton style={{ cursor: "default" }}>
                  <Comment />
                  <i>댓글</i>
                  <span>{handleNumber(total)}</span>
                </LikeButton>
                {modal?.type === "vcard" && (
                  <Together
                    type="button"
                    onClick={() =>
                      setWithModal({
                        cardId: data?.vcardId,
                        cardThumb: data?.vcardImage,
                        id: data?.receiveUserId,
                        user: `${data?.receiveUserName}${
                          data?.receiveUserDepartment
                            ? " " + data?.receiveUserDepartment
                            : ""
                        }`,
                      })
                    }
                  >
                    <TogetherIcon />
                    함께 응원하기
                  </Together>
                )}
              </CountArea>
            </MainContent>
          </FeedContent>
          <CommentBox>
            <div>
              <CommentInput
                type="text"
                placeholder="댓글을 입력해보세요"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyPress={(e) => {
                  if (
                    (e.code.toLowerCase() === "enter" || e.charCode === 13) &&
                    comment !== "" &&
                    edit === undefined
                  )
                    handleComment();
                }}
              />
              {edit && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setComment("");
                      setEdit(undefined);
                    }}
                  >
                    취소
                  </button>
                  <button type="button" onClick={handleEdit}>
                    수정
                  </button>
                </>
              )}
            </div>
          </CommentBox>
          {listData?.length > 0 &&
            listData.map((item, i) => (
              <CommentList
                key={"comment_list" + i}
                item={item}
                mutate={() => {
                  mutate2();
                  modal?.mutate && modal?.mutate();
                }}
                userId={modal?.userId}
                setEditMobile={setEdit}
                setCommandMobile={setComment}
              />
            ))}
        </Content>
      </Wrapper>
    </ModalWrapper>
  );
};

export default DetailModal;

const CommentInput = styled.input`
  width: 100%;
  height: 52px;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: ${(props) => props.theme.color.gray1};
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }

  @media (max-width: 1024px) {
    height: auto;
    padding: 0;
  }
`;

const CommentBox = styled.div`
  width: 100%;

  & > div {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.color.gray7};
    display: flex;
    align-items: flex-end;
    padding: 13px 0;

    & > button {
      margin-left: 15px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.6;
      color: ${(props) => props.theme.color.gray4};

      &:nth-of-type(2) {
        color: ${(props) => props.theme.color.blue};
      }
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 20px 10px;
    height: 58px;
  }
`;

const Tags = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  color: ${(props) => props.theme.color.sub};

  & > span {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const TagUser = styled.div`
  width: fit-content;
  margin-top: 20px;
  padding: 10px 14px;
  border-radius: 36px;
  background: ${(props) => props.theme.color.sub};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray8};
  cursor: pointer;

  & > b {
    font-weight: 600;
    margin: 0 4px 0 6px;
    color: white;
  }

  & div {
    position: relative;

    & > img {
      border-radius: 20px;
      object-fit: cover;
    }

    & > img.profile {
      width: 22px;
      height: 22px;
      border: 1px solid ${(props) => props.theme.color.gray8};
    }

    & > img.tag {
      position: absolute;
      bottom: 0;
      right: -2.27px;
      width: 10px;
      height: 10px;
    }
  }
`;

const Together = styled.button`
  margin-left: auto;
  padding: 8px 10px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.sub};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: white;
  display: flex;
  align-items: center;

  & > svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
`;

const LikeButton = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.gray7};
  background: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  cursor: pointer;

  & > span {
    margin-left: 6px;
    display: block;
  }

  & > b {
    font-weight: 600;
    padding-left: 6px;
  }

  & > i {
    font-style: normal;
  }

  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.color.sub};
    border-color: ${(props) => props.theme.color.sub};

    & > span {
      font-weight: 600;
    }

    & > b {
      font-weight: 700;
    }
  }

  &:nth-of-type(n + 2) {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    & > b,
    & > i {
      display: none;
    }

    & > span {
      margin: 0;
      padding: 0 4px;
    }

    &:nth-of-type(n + 2) {
      margin-left: 8px;
    }
  }
`;

const CountArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: black;

  & > svg {
    width: 10px;
    height: 6px;
    margin-left: 4px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentText = styled.div`
  max-height: 290px;
  position: relative;
  margin: 20px 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: black;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  transition: all 0.3s;

  &.show {
    -webkit-line-clamp: 1000;
    overflow: auto;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const Department = styled.div`
  margin-left: auto;
  border-radius: 4px;
  background: ${(props) => props.theme.color.gray8};
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 8px 10px;
    font-size: 12px;
  }
`;

const Name = styled.div`
  width: 100%;

  & > h1 {
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 8px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray4};
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 13px;
      margin-bottom: 5px;
    }

    & > p {
      font-size: 10px;
    }
  }
`;

const Profile = styled.div`
  position: relative;
  margin-right: 14px;
  cursor: pointer;

  & > img {
    border-radius: 40px;
    object-fit: cover;
  }

  & > img.profile {
    width: 40px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.color.gray8};
  }

  & > img.tag {
    position: absolute;
    bottom: 0;
    right: -4px;
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    margin-right: 10px;

    & > img.profile {
      width: 30px;
      height: 30px;
    }

    & > img.tag {
      right: -2px;
      width: 14px;
      height: 14px;
    }
  }
`;

const ProfileArea = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 768px) {
    display: none;
    margin-bottom: 14px;

    &.mobile {
      display: flex;
    }
  }
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Image = styled.div`
  max-width: 354px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 26px;

  & > div {
    width: 100%;
    padding-top: 100%;
    position: relative;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1400px) {
    max-width: 320px;
  }

  @media (max-width: 1200px) {
    max-width: 300px;
  }

  @media (max-width: 1140px) {
    max-width: 280px;
  }

  @media (max-width: 768px) {
    max-width: 354px;
    margin: 0 auto 14px;
  }
`;

const ReceiveDepart = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.gray8};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-left: auto;

  @media (max-width: 768px) {
    padding: 8px 10px;
    font-size: 12px;
  }
`;

const ToBox = styled.div`
  padding: 13px 20px;
  background: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: white;

  & > span {
    display: block;
    width: 100%;
    font-weight: 500;
  }

  & > img {
    margin: 0 10px 0 4px;
    width: 38px;
    height: 38px;
    border-radius: 40px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding: 13px 16px;
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 81.92%;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding-top: 80%;
  }
`;

const Vcard = styled.div`
  max-width: 354px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 26px;

  @media (max-width: 1400px) {
    max-width: 320px;
  }

  @media (max-width: 1200px) {
    max-width: 300px;
  }

  @media (max-width: 1140px) {
    max-width: 280px;
  }

  @media (max-width: 768px) {
    max-width: 354px;
    margin: 0 auto 14px;
  }
`;

const FeedContent = styled.div`
  margin-bottom: 30px;

  &.vcard,
  &.image {
    display: flex;
    align-items: stretch;
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    margin-bottom: 5px;

    &.vcard,
    &.image {
      display: block;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  @media (max-width: 1024px) {
    margin-top: 0;
    max-height: calc(100% - 54px - 58px);
    overflow: auto;
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
  }
`;
