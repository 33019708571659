import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CommentType } from "../../types/api";
import moment from "moment";
import { api } from "../../utils/api";
import LikeButton from "../elements/LikeButton";
import CommentMenuButton from "../elements/CommentMenuButton";
import { userModalState } from "../../utils/atom";
import { useSetRecoilState } from "recoil";

import { ReactComponent as LikesAct } from "../../assets/icons/likes-active-icon.svg";
import { ReactComponent as Likes } from "../../assets/icons/likes-icon.svg";
import { ReactComponent as LikeAct } from "../../assets/icons/like-active-icon.svg";
import { ReactComponent as Like } from "../../assets/icons/like-icon.svg";

import DefaultProfile from "../../assets/images/proflie-image.svg";
import { useTranslation } from "react-i18next";

const CommentList = ({
  item,
  mutate,
  userId,
  date = false,
  setEditMobile,
  setCommandMobile,
}: {
  item: CommentType;
  mutate: () => void;
  userId: number;
  date?: boolean;
  setEditMobile?: (vlaue: number) => void;
  setCommandMobile?: (vlaue: string) => void;
}) => {
  const { t } = useTranslation("");
  const setUserModal = useSetRecoilState(userModalState);

  const [first, setFirst] = useState<boolean>(false);
  const [like, setLike] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setFirst(item?.myLike === 1);
    setLike(item?.myLike === 1);
  }, [item]);

  const handleLike = async () => {
    const formData = JSON.stringify({
      commentId: item.id,
    });

    const {
      data: { success, message, data: data2 },
    } = await api.post("/comment/like", formData);

    if (success) {
      mutate();
      setLike(data2 === "0");
    } else {
      window.alert(message);
    }
  };

  const handleEdit = async () => {
    const formData = JSON.stringify({
      commentId: item.id,
      contents: comment,
    });

    const {
      data: { success, message, data },
    } = await api.post("/comment/modify", formData);

    if (success) {
      mutate();
      setEdit(false);
    } else {
      window.alert(message);
    }
  };

  return (
    <Wrapper>
      {item?.userId === userId && !edit && (
        <CommentMenuButton
          id={item?.id}
          mutate={mutate}
          setEdit={(value) => {
            if (window.outerWidth > 1024) {
              setComment(item?.contents);
              setEdit(value);
            } else {
              setEditMobile && setEditMobile(item?.id);
              setCommandMobile && setCommandMobile(item?.contents);
            }
          }}
        />
      )}
      <MediaBox>
        <img
          src={item?.profileImage ? item?.profileImage : DefaultProfile}
          alt=""
          onClick={() => setUserModal({ id: item?.userId })}
        />
        <TextArea>
          <NameBox>
            {item?.name}
            <span>{item?.department}</span>
            {date && !edit && (
              <p>{moment(item?.createdAt).format("YYYY.MM.DD HH:mm")}</p>
            )}
          </NameBox>
          {edit ? (
            <InputArea>
              <EditInput
                type="text"
                placeholder={`${t("feed_commendText")}`}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button type="button" onClick={() => setEdit(false)}>
                {t("post_cancle")}
              </button>
              <button type="button" onClick={handleEdit}>
                {t("mypage_basic_edit")}
              </button>
            </InputArea>
          ) : (
            <>
              <Comment>{item?.contents}</Comment>
              <LikeBox>
                <LikeButton active={like} onClick={handleLike}>
                  {item?.likeCount > 1 ? (
                    like ? (
                      <LikesAct />
                    ) : (
                      <Likes />
                    )
                  ) : like ? (
                    <LikeAct />
                  ) : (
                    <Like />
                  )}
                  {t("feed_like")}
                  <span>
                    {item?.likeCount + (first ? (like ? 0 : -1) : like ? 1 : 0)}
                  </span>
                </LikeButton>
              </LikeBox>
            </>
          )}
        </TextArea>
      </MediaBox>
      <MediaBox className="mobile">
        <TextArea>
          <NameBox>
            <img
              src={item?.profileImage ? item?.profileImage : DefaultProfile}
              alt=""
              onClick={() => setUserModal({ id: item?.userId })}
            />
            {item?.name}
            <span>{item?.department}</span>
            {date && !edit && (
              <p>{moment(item?.createdAt).format("YYYY.MM.DD HH:mm")}</p>
            )}
          </NameBox>
          <Comment>{item?.contents}</Comment>
          <LikeBox>
            <LikeButton active={like} onClick={handleLike}>
              {item?.likeCount > 1 ? (
                like ? (
                  <LikesAct />
                ) : (
                  <Likes />
                )
              ) : like ? (
                <LikeAct />
              ) : (
                <Like />
              )}
              <i>{t("feed_like")}</i>
              <span>
                {item?.likeCount + (first ? (like ? 0 : -1) : like ? 1 : 0)}
              </span>
            </LikeButton>
          </LikeBox>
        </TextArea>
      </MediaBox>
    </Wrapper>
  );
};

export default CommentList;

const EditInput = styled.input`
  width: 100%;
  background: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: black;

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.gray3};

  & > button {
    margin-left: 14px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
    color: ${(props) => props.theme.color.gray4};

    &:nth-of-type(2) {
      color: ${(props) => props.theme.color.blue};
    }
  }
`;

const LikeBox = styled.div``;

const Comment = styled.div`
  margin: 6px 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  white-space: pre-wrap;
  word-break: break-all;

  @media (max-width: 1024px) {
    margin: 10px 0;
    font-size: 13px;
  }
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > span {
    margin: 0 6px;
    display: block;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray3};
  }

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray6};
  }

  @media (max-width: 1024px) {
    & > img {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 6px;
    }

    & > p {
      display: none;
    }
  }
`;

const TextArea = styled.div`
  width: calc(100% - 44px);

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const MediaBox = styled.div`
  width: 100%;
  padding: 20px;
  background: #fafafa;
  display: flex;
  align-items: flex-start;

  & > img {
    margin-right: 14px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    object-fit: cover;
    cursor: pointer;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none;
    padding: 16px 20px;

    &.mobile {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
