import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { alertModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { PopupTitle, PopupText } from "../elements/Typography";

const AlertModal = () => {
  const [modal, setModal] = useRecoilState(alertModalState);

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };
  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <PopupTitle className="center">{modal.title}</PopupTitle>
        <PopupText
          className="center"
          dangerouslySetInnerHTML={{ __html: modal.text }}
        />
        <Button onClick={handleConfirm}>{modal?.confirmText || "확인"}</Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default AlertModal;

const Wrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 50px 30px 30px;
  background: white;
  border-radius: 20px;

  & > p {
    margin: 10px 0 40px;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px 20px;

    & > p {
      margin: 10px 0 30px;
    }
  }
`;
