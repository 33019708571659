import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../elements/Button";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as Icon } from "../../../assets/icons/qna-icon.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/white-down-arrow-icon.svg";
import { useTranslation } from "react-i18next";

const QnaBox = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("");

  const textRef = useRef(null);
  const arrowRef = useRef(null);

  const [pc, setPc] = useState(true);

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  const handleInfo = () => {
    const el: any = textRef.current;
    const el2: any = arrowRef.current;

    if (el !== null) {
      if (el.style.height === "0px" || !el.style.height) {
        el.style.height = el.scrollHeight + "px";
        el2.style.transform = "rotate(0deg)";
      } else {
        el.style.height = "0px";
        el2.style.transform = "rotate(180deg)";
      }
    }
  };

  return (
    <Wrapper>
      <Title onClick={handleInfo}>
        <Icon />
        {t("main_contect")}
        <Arrow className="arrow" ref={arrowRef} />
      </Title>
      <Content ref={textRef}>
        <div>
          {t("main_contect_phone")} : 1599-9999
          <br />
          {t("main_contect_email")} : support@viva.com
          <Button
            size="sm"
            styleType={pc ? "line_gray_white" : "line_white_white"}
            onClick={() => navigate(`/faq`)}
          >
            {t("main_faq")}
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default QnaBox;

const Content = styled.div`
  transition: all 0.3s;
  height: 0;
  overflow: hidden;

  & > div {
    padding-top: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.7;
    color: white;

    & > button {
      margin-top: 20px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: white;

  & > svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    &.arrow {
      height: 6px;
      width: 10px;
      margin: 0;
      margin-left: auto;
      transition: all 0.3s;
    }
  }
`;

const Wrapper = styled.div`
  padding: 15px 16px;
  border-radius: 10px;
  background: ${(props) => props.theme.color.sub};
`;
