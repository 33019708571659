import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, joinModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import Button from "../elements/Button";
import { api } from "../../utils/api";

const JoinModal = () => {
  const [modal, setModal] = useRecoilState(joinModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const [pc, setPc] = useState(true);

  const { data } = useSWR(
    modal !== null && `/challenge/detail?challengeId=${modal?.id}`
  );

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  const handleClose = () => {
    setModal(null);
  };

  const handleJoin = async () => {
    const formData = new FormData();
    formData.append("challengeId", String(modal?.id));

    const {
      data: { success, message, data },
    } = await api.post("/challenge/enter", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
      setAlertModal({
        title: "챌린지 참가 신청 완료",
        text: "챌린지 참가 신청을 완료했습니다.",
      });
    } else {
      window.alert(message);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {pc ? (
            <>
              <img src={data?.challenge?.image} />
              <TitleText
                dangerouslySetInnerHTML={{
                  __html: `<b>${data?.challenge?.title}</b><br />${
                    data?.challenge?.type === "auto"
                      ? "자동 달성 챌린지"
                      : "참가형 챌린지"
                  }`,
                }}
              />
            </>
          ) : (
            "참가 신청"
          )}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          {!pc && (
            <Info>
              <img src={data?.challenge?.image} />
              <TitleText>
                <b>{data?.challenge?.title}</b>
                <br />
                {data?.challenge?.type === "auto"
                  ? "자동 달성 챌린지"
                  : "참가형 챌린지"}
              </TitleText>
            </Info>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: data?.challenge?.contents }}
          />
        </Content>
        <ButtonArea>
          <Button onClick={handleJoin}>챌린지 참가 신청</Button>
          <Button styleType="line" onClick={handleClose}>
            닫기
          </Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default JoinModal;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > img {
    width: 43px;
    min-width: 43px;
    height: 43px;
    border-radius: 50px;
    object-fit: cover;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  max-width: 740px;
  margin: auto;

  & > button:nth-of-type(n + 2) {
    margin-left: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
    max-width: 100%;
    justify-content: space-between;

    & > button {
      max-width: calc(50% - 5px);
    }

    & > button:nth-of-type(n + 2) {
      margin-left: 10px;
    }
  }
`;

const Content = styled.div`
  margin: 30px 0;
  overflow: auto;
  max-height: 500px;

  & > div {
    white-space: pre-wrap;
  }

  @media (max-width: 1024px) {
    margin: 0;
    padding: 20px;
    width: 100%;
    max-height: calc(100% - 54px - 90px);
    height: 100%;
  }
`;

const TitleText = styled.div`
  width: 100%;
  margin: 0 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray1};

  & > b {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    margin: 0;
    margin-left: 10px;
    font-size: 13px;

    & > b {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;

  & > img {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 50px;
    object-fit: cover;
  }

  & > svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
  }
`;
