import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChildrenType } from "../../types/types";
import Header from "./mobile/Header";
import Footer from "./mobile/Footer";
import GNB from "./mobile/GNB";
import { useParams } from "react-router-dom";

const MobileWrapper = ({ children }: ChildrenType) => {
  const params = useParams();

  return (
    <>
      {window.location.pathname.includes(`/${params?.company}`) ||
      window.location.pathname === `/` ||
      window.location.pathname.includes("/user") ||
      window.location.pathname.includes("/faq") ||
      window.location.pathname.includes("/challenge") ||
      window.location.pathname.includes("/alarm") ||
      window.location.pathname.includes("/rank") ||
      window.location.pathname.includes("/search") ? (
        <Wrapper>{children}</Wrapper>
      ) : window.location.pathname.includes("/mypage") ? (
        <Wrapper className="mypage">
          {children}
          <GNB />
        </Wrapper>
      ) : (
        <Wrapper className="none_user">
          <Header />
          {children}
          <Footer />
          <GNB />
        </Wrapper>
      )}
    </>
  );
};

export default MobileWrapper;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;

  &.none_user {
    padding: 54px 0 61px;
  }

  &.mypage {
    padding-bottom: 61px;
  }
`;
