import React, { useState } from "react";
import styled from "styled-components";
import { InputType } from "../../types/types";

import { ReactComponent as Eye } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeG } from "../../assets/icons/eye-gray-icon.svg";
import { Dot } from "../../utils/CommonFunction";

const Input = ({
  label,
  errorText,
  placeholder,
  type = "text",
  value,
  onChange,
  onBlur,
  viva,
  ...props
}: InputType) => {
  const [eye, setEye] = useState<boolean>(false);

  return (
    <Label>
      {label && label !== "" && <p> {label}</p>}
      <InputBox>
        <StyledInput
          className={window.location.href.includes("/user") ? "" : "bold"}
          type={type === "password" ? (eye ? "text" : "password") : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={(e) => onBlur && onBlur(e)}
          {...props}
        />
        {type === "password" &&
          (window.location.href !== "/" ? (
            <EyeG onClick={() => setEye(!eye)} />
          ) : (
            <Eye onClick={() => setEye(!eye)} />
          ))}
        {viva !== undefined && <Viva>Viva</Viva>}
      </InputBox>
      {((errorText && errorText !== "") || viva !== undefined) && (
        <Bottom>
          {errorText && errorText !== "" && <Error>{errorText}</Error>}
          {viva !== undefined && (
            <Point>
              선물 가능 포인트 <b>{Dot(viva)} Viva</b>
            </Point>
          )}
        </Bottom>
      )}
    </Label>
  );
};

export default Input;

const Bottom = styled.div`
  margin-top: 8px;
  display: flex;
`;

const Point = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-left: auto;
`;

const Viva = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  color: ${(props) => props.theme.color.sub};
  margin-left: auto;
`;

const Error = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.red};
`;

const StyledInput = styled.input`
  width: calc(100% - 75px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray1};
  background: none;

  &.bold {
    font-weight: 600;
  }

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }
`;

const InputBox = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  border-radius: 6px;
  background: #fafafa;

  & > svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-left: auto;
  }
`;

const Label = styled.label`
  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
    margin-bottom: 6px;
  }
`;
