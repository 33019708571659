import React, { useEffect, useState } from "react";
import { RouteType } from "../../types/types";
import PcWrapper from "../wrapper/PcWrapper";
import MobileWrapper from "../wrapper/MobileWrapper";

import AlertModal from "../modal/AlertModal";
import TwoButtonModal from "../modal/TwoButtonModal";
import LikeModal from "../modal/LikeModal";
import CommentModal from "../modal/CommentModal";
import FeedPostModal from "../modal/FeedPostModal";
import DetailModal from "../modal/DetailModal";
import UserModal from "../modal/UserModal";
import VCardPostModal from "../modal/VCardPostModal";
import CompleteModal from "../modal/CompleteModal";
import JoinModal from "../modal/JoinModal";
import CardDetailModal from "../modal/CardDetailModal";
import ChangePasswordModal from "../modal/ChangePasswordModal";
import BedgeModal from "../modal/BedgeModal";
import TermModal from "../modal/TermModal";
import { redirect, useNavigate, useParams } from "react-router-dom";
import CommentMenuModal from "../modal/CommentMenuModal";
import FilterMenuModal from "../modal/FilterMenuModal";
import FeedMenuModal from "../modal/FeedMenuModal";
import ChallengePostModal from "../modal/ChallengePostModal";
import auth from "../../utils/auth";

const WrapperRoute = ({ element, ...props }: RouteType) => {
  let pcDevice = "win16|win32|win64|mac|macintel";
  const params = useParams();
  const navigate = useNavigate();

  const [pc, setPc] = useState(true);

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });

    if (window.localStorage.getItem("changePw"))
      navigate("/user/change", { replace: true });
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("undefined")) return;
    if (
      window.location.pathname.includes(`${params?.company}`) ||
      window.location.pathname === `/`
    ) {
      window.sessionStorage.clear();
    } else if (window.location.pathname.includes(`/user`)) {
    } else if (auth.getToken() || auth.getTokenSession()) {
    } else {
      navigate("/", { replace: true });
    }
  }, [window.location.href]);

  return (
    <>
      {pcDevice.indexOf(navigator.platform.toLowerCase()) >= 0 && pc ? (
        <PcWrapper>{element}</PcWrapper>
      ) : (
        <MobileWrapper>{element}</MobileWrapper>
      )}

      <DetailModal />
      <CardDetailModal />
      <ChangePasswordModal />
      <ChallengePostModal />

      <UserModal />
      <LikeModal />
      <CommentModal />
      <FeedPostModal />
      <VCardPostModal />
      <JoinModal />
      <BedgeModal />

      <CommentMenuModal />
      <FilterMenuModal />
      <FeedMenuModal />

      <TwoButtonModal />
      <AlertModal />
      <CompleteModal />
      <TermModal />
    </>
  );
};

export default WrapperRoute;
