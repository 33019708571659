import React from "react";
import { ChildrenType } from "../../../types/types";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../../assets/icons/logo-icon.svg";

const UserWrapper = ({ children }: ChildrenType) => {
  return (
    <Wrapper>
      <Left>
        <Logo />
      </Left>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default UserWrapper;

const Content = styled.div`
  max-width: 50%;
  width: 100%;
  height: 100%;
`;

const Left = styled.div`
  padding: 40px;
  max-width: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${require("../../../assets/images/user-page-bg.png")})
    no-repeat center/cover;

  & > svg {
    width: 290px;
    height: 160px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
