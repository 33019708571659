import React from "react";
import styled from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  active: boolean;
};

const LikeButton = ({ children, active, ...props }: ButtonProps) => {
  return (
    <Wrapper {...props} className={active ? "active" : ""}>
      {children}
    </Wrapper>
  );
};

export default LikeButton;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.gray7};
  background: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > i {
    font-style: normal;
  }

  & > span {
    margin-left: 6px;
    display: block;
  }

  & > svg {
    margin-right: 6px;
  }

  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.color.sub};
    border-color: ${(props) => props.theme.color.sub};

    & > span {
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    & > i {
      display: none;
    }

    & > span {
      margin: 0;
      padding: 0 4px;
    }
  }
`;
