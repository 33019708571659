import React from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { termModalState } from "../../../utils/atom";
import useSWR from "swr";

import { ReactComponent as Logo } from "../../../assets/icons/viva-logo-icon.svg";

const Footer = () => {
  const setTermModal = useSetRecoilState(termModalState);

  const { data } = useSWR(`/profile`);

  return (
    <>
      <InfoArea>
        <Logo />
        <Info>
          <b>대표이사</b>&nbsp;<span>|</span>
          &nbsp;홍길동&nbsp;&nbsp;<b>주소</b>
          &nbsp;<span>|</span>
          &nbsp;서울특별시&nbsp;성동구&nbsp;안암로&nbsp;3길&nbsp;51,&nbsp;4-6층
          <br />
          <b>사업자등록번호</b>&nbsp;
          <span>|</span>&nbsp;545-86-01374
          <br />
          <b>통신판매업신고</b>&nbsp;<span>|</span>
          &nbsp;2023-서울중구-0350호&nbsp;&nbsp;<b>고객센터</b>&nbsp;
          <span>|</span>
          &nbsp;1599-9999
          <br />
          <b>E-mail</b>&nbsp;<span>|</span>
          &nbsp;support@viva.com
        </Info>
        <Term>
          <button
            type="button"
            onClick={() => setTermModal({ type: "service" })}
          >
            서비스 이용약관
          </button>
          <span />
          <button
            type="button"
            onClick={() => setTermModal({ type: "privacy" })}
          >
            개인정보 처리방침
          </button>
          {data && data?.isAdmin === 1 && (
            <>
              <span />
              <button
                type="button"
                onClick={() =>
                  window.open("https://enterprise.vivalounge.fifteenh.io/login")
                }
              >
                기업 관리자
              </button>
            </>
          )}
        </Term>
      </InfoArea>
      <Copyright>COPYRIGHT © 법인명. All right reserved</Copyright>
    </>
  );
};

export default Footer;

const Copyright = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.color.gray8};
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray6};
`;

const Term = styled.div`
  display: flex;
  align-items: center;

  & > button {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    color: ${(props) => props.theme.color.gray4};
  }

  & > span {
    display: block;
    width: 1px;
    height: 11px;
    margin: 0 16px;
    background: ${(props) => props.theme.color.gray7};
  }
`;

const Info = styled.div`
  margin: 18px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: -0.036px;
  color: ${(props) => props.theme.color.gray3};
  opacity: 0.8;

  & > span {
    color: ${(props) => props.theme.color.gray7};
  }

  & > b {
    font-weight: 700;
    opacity: 1;
  }
`;

const InfoArea = styled.div`
  padding: 25px 20px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.gray7};
  background: ${(props) => props.theme.color.gray8};

  & > svg {
    margin-top: 6px;
    width: 130px;
    height: 22px;
  }
`;
