import React from "react";
import styled from "styled-components";

const JoinTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.color.gray1};
`;

const PopupTitle = styled.h1`
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: ${(props) => props.theme.color.gray1};

  &.center {
    text-align: center;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 1;
  }
`;

const PopupText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: black;

  & > b {
    font-weight: 500;
  }

  &.center {
    text-align: center;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    color: ${(props) => props.theme.color.gray2};
  }
`;

const UserTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;

  & > svg {
    display: none;
  }

  @media (max-width: 1024px) {
    position: fixed;
    width: 100%;
    height: 54px;
    z-index: 1;
    top: 0;
    left: 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    & > svg {
      display: block;
      position: fixed;
      top: 18px;
      left: 20px;
    }
  }
`;

export { JoinTitle, PopupTitle, PopupText, UserTitle };
