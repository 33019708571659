import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { feedPostModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import Textarea from "../elements/Textarea";
import Input from "../elements/Input";
import ImageUpload from "../elements/ImageUpload";
import Select from "../elements/Select";
import ToggleButton from "../elements/ToggleButton";
import UserTagSelect from "../elements/UserTagSelect";
import Button from "../elements/Button";
import { api } from "../../utils/api";
import { OptionType } from "../../types/types";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

import DefaultProfile from "../../assets/images/proflie-image.svg";

const FeedPostModal = () => {
  const [modal, setModal] = useRecoilState(feedPostModalState);

  const [content, setContent] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [image, setImage] = useState<File>();
  const [tagUser, setTagUser] = useState<OptionType>();
  const [alarm, setAlarm] = useState<boolean>(false);
  const [publicStatus, setPublicStatus] = useState<OptionType>();
  const [pc, setPc] = useState(true);

  const { data: profile } = useSWR(modal !== null && `/profile`);
  const { data: editData } = useSWR(
    modal !== null && modal?.edit && `/feed/detail?feedId=${modal?.id}`
  );

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  useEffect(() => {
    if (editData) {
      setContent(editData?.feed?.contents);
      setTags(editData?.feed?.tags);
      if (
        editData?.tagUser?.id &&
        editData?.tagUser?.name &&
        editData?.tagUser?.department
      )
        setTagUser({
          value: editData?.tagUser?.id,
          text: `${editData?.tagUser?.name} ${editData?.tagUser?.department}`,
        });
      setAlarm(editData?.feed?.sendAlarm === 1);
      setPublicStatus({
        value: `${editData?.feed?.publicStatus}`,
        text:
          Number(editData?.feed?.publicStatus) === 1
            ? "전체 노출"
            : "부서 노출",
      });
    }
  }, [editData]);

  const handleClose = () => {
    setModal(null);
    setContent("");
    setTags("");
    setImage(undefined);
    setTagUser(undefined);
    setAlarm(false);
    setPublicStatus(undefined);
  };

  const handleSubmit = async (e: any) => {
    e.target.disabled = true;

    const formData = new FormData();

    if (modal?.edit) formData.append("feedId", String(modal?.id));
    formData.append("contents", content);
    formData.append("tags", tags);
    formData.append("sendAlarm", String(alarm ? 1 : 0));
    if (tagUser?.value) formData.append("tagUserId", tagUser?.value);
    formData.append("publicStatus", String(publicStatus?.value));
    if (image) formData.append("image", image);

    const {
      data: { success, message, data },
    } = await api.post(
      modal?.edit ? "/feed/modify" : "/feed/register",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (success) {
      handleClose();
      window.location.reload();
    } else {
      window.alert(message);
      e.target.disabled = false;
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {pc ? (
            <>
              <Profile>
                <img
                  className="profile"
                  src={
                    profile?.profileUrl ? profile?.profileUrl : DefaultProfile
                  }
                />
                <img className="tag" src={profile?.badgeImage} />
              </Profile>
              <p>{profile?.name}</p>
              <span>{profile?.department}</span>
            </>
          ) : (
            "일반 피드 작성"
          )}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          {!pc && (
            <MobileProfile>
              <Profile>
                <img
                  className="profile"
                  src={
                    profile?.profileUrl ? profile?.profileUrl : DefaultProfile
                  }
                />
                <img className="tag" src={profile?.badgeImage} />
              </Profile>
              <p>{profile?.name}</p>
              <span>{profile?.department}</span>
            </MobileProfile>
          )}
          <Textarea
            placeholder={`${
              profile?.name ? profile?.name : ""
            } 님, 어떤 내용을 작성하시겠어요?`}
            value={content}
            onChange={(e) => setContent(e.target.value.slice(0, 1000))}
            max={1000}
          />
          <Input
            label="해시태그"
            placeholder="해시태그 입력 (쉼표로 구분)"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
          <ImageUpload
            label="이미지 업로드"
            image={
              image
                ? image?.name
                : modal?.edit
                ? editData?.feed?.image
                  ? editData?.feed?.image?.split("/")[
                      editData?.feed?.image?.split("/")?.length - 1
                    ]
                  : ""
                : ""
            }
            onChange={(file) => setImage(file)}
          />
          <FlexBox>
            <UserTagSelect
              label="태그"
              placeholder="태그대상 검색"
              value={tagUser}
              onChange={(value) => setTagUser(value)}
            />
            <Select
              label="공개범위"
              placeholder="공개범위"
              keyValue="public_"
              value={publicStatus}
              options={[
                { value: "1", text: "전체 노출" },
                { value: "2", text: "부서 노출" },
              ]}
              onChange={(value) => setPublicStatus(value)}
            />
          </FlexBox>
          <ToggleBox onClick={() => setAlarm(!alarm)}>
            알림발송여부
            <ToggleButton checked={alarm} onClick={() => {}} />
          </ToggleBox>
        </Content>
        <MobileButtonBox>
          <Button
            onClick={handleSubmit}
            disabled={content === "" || !publicStatus}
          >
            피드{modal?.edit ? " 수정" : " 게시"}
          </Button>
        </MobileButtonBox>
        <Button
          onClick={handleSubmit}
          disabled={content === "" || !publicStatus}
        >
          피드{modal?.edit ? " 수정" : " 게시"}
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default FeedPostModal;

const MobileButtonBox = styled.div`
  padding: 20px;
  background: white;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MobileProfile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > p {
    width: 100%;
    margin: 0 20px 0 10px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  & > span {
    padding: 8px 10px;
    border-radius: 4px;
    background: ${(props) => props.theme.color.gray8};
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
    white-space: nowrap;
  }
`;

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-top: 20px;

  & > div {
    margin-left: 20px;
  }
`;

const FlexBox = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > label {
    max-width: calc(50% - 10px);
  }

  @media (max-width: 1024px) {
    display: block;
    margin: 0;

    & > label {
      display: block;
      max-width: 100%;
      margin-top: 20px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 30px 0 50px;

  & > label {
    margin-top: 20px;
    display: block;
  }

  @media (max-width: 1024px) {
    max-height: calc(100% - 54px - 90px);
    height: 100%;
    margin: 0;
    padding: 20px;
    overflow: auto;
  }
`;

const Profile = styled.div`
  position: relative;
  margin-right: 14px;

  & > img {
    border-radius: 40px;
    object-fit: cover;
  }

  & > img.profile {
    width: 40px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.color.gray8};
  }

  & > img.tag {
    position: absolute;
    bottom: 0;
    right: -4px;
    width: 18px;
    height: 18px;
  }

  @media (max-width: 1024px) {
    margin-right: 10px;

    & > img.profile {
      width: 30px;
      height: 30px;
    }

    & > img.tag {
      right: -2px;
      width: 14px;
      height: 14px;
    }
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;

  & > p {
    width: 100%;
    margin: 0 14px 0 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  & > span {
    margin: 0 20px 0 10px;
    padding: 8px;
    border-radius: 4px;
    background: ${(props) => props.theme.color.gray8};
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
    white-space: nowrap;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  & > button {
    display: block;
    max-width: 360px;
    margin: auto;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;

    & > button {
      display: none;
    }
  }
`;
