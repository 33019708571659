import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  cardDetailModalState,
  userModalState,
  vcardPostModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import moment from "moment";
import Button from "../elements/Button";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

import DefaultProfile from "../../assets/images/proflie-image.svg";

const CardDetailModal = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [modal, setModal] = useRecoilState(cardDetailModalState);
  const [pc, setPc] = useState(true);

  const setWithModal = useSetRecoilState(vcardPostModalState);
  const setUserModal = useSetRecoilState(userModalState);

  const { data, mutate } = useSWR(
    modal !== null && `/vcard/detail?vcardId=${modal?.id}`
  );

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {!pc && "V-CARD 상세"}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          <Vcard>
            <CardImage>
              <img src={data?.vcardImage} />
            </CardImage>
            <ToBox
              onClick={() =>
                setUserModal({
                  id: data?.receiveUserId,
                })
              }
            >
              To.
              <img
                src={
                  data?.receiveUserProfileImage
                    ? data?.receiveUserProfileImage
                    : DefaultProfile
                }
                alt=""
              />
              {data?.receiveUserName}
              {data?.receiveUserDepartment && (
                <ReceiveDepart>{data?.receiveUserDepartment}</ReceiveDepart>
              )}
            </ToBox>
          </Vcard>
          <MainContent>
            <ProfileArea>
              <Profile
                onClick={() =>
                  setUserModal({
                    id: data?.sendUserId,
                  })
                }
              >
                <img
                  src={
                    data?.sendUserProfileImage
                      ? data?.sendUserProfileImage
                      : DefaultProfile
                  }
                  alt=""
                  className="profile"
                />
                {data?.sendUserBadgeImage && (
                  <img src={data?.sendUserBadgeImage} alt="" className="tag" />
                )}
              </Profile>
              <Name>
                <h1>{data?.sendUserName}</h1>
                <p>{moment(data?.createdAt).format("YYYY.MM.DD HH:mm")}</p>
              </Name>
              <Department>{data?.sendUserDepartment}</Department>
            </ProfileArea>
            <ContentText
              dangerouslySetInnerHTML={{
                __html: data?.contents,
              }}
            />
          </MainContent>
        </Content>
        <ButtonArea>
          <Button
            onClick={() => {
              navigate(`/card`);
              handleClose();
            }}
          >
            V-CARD 발송
          </Button>
          <Button styleType="line" onClick={handleClose}>
            취소
          </Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CardDetailModal;

const ButtonArea = styled.div`
  max-width: 740px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    max-width: calc(50% - 10px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;

    & > button {
      max-width: calc(50% - 5px);
    }
  }
`;

const ContentText = styled.div`
  max-height: 320px;
  margin: 20px 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: black;
  overflow: auto;

  @media (max-width: 768px) {
    max-height: auto;
    margin: 14px 0 0;
    font-size: 13px;
  }
`;

const Department = styled.div`
  margin-left: auto;
  border-radius: 4px;
  background: ${(props) => props.theme.color.gray8};
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 8px 10px;
    font-size: 12px;
  }
`;

const Name = styled.div`
  width: 100%;

  & > h1 {
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 8px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray4};
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 13px;
      margin-bottom: 5px;
    }

    & > p {
      font-size: 10px;
    }
  }
`;

const Profile = styled.div`
  position: relative;
  margin-right: 14px;
  cursor: pointer;

  & > img {
    border-radius: 40px;
    object-fit: cover;
  }

  & > img.profile {
    width: 40px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.color.gray8};
  }

  & > img.tag {
    position: absolute;
    bottom: 0;
    right: -4px;
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    margin-right: 10px;

    & > img.profile {
      width: 30px;
      height: 30px;
    }

    & > img.tag {
      right: -2px;
      width: 14px;
      height: 14px;
    }
  }
`;

const ProfileArea = styled.div`
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  width: 100%;
`;

const ReceiveDepart = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.color.gray8};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-left: auto;

  @media (max-width: 768px) {
    padding: 8px 10px;
    font-size: 12px;
  }
`;

const ToBox = styled.div`
  padding: 13px 20px;
  background: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: white;

  & > span {
    display: block;
    width: 100%;
    font-weight: 500;
  }

  & > img {
    margin: 0 10px 0 4px;
    width: 38px;
    height: 38px;
    border-radius: 40px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding: 13px 16px;
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 81.92%;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding-top: 80%;
  }
`;

const Vcard = styled.div`
  max-width: 354px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 26px;

  @media (max-width: 1400px) {
    max-width: 320px;
  }

  @media (max-width: 1200px) {
    max-width: 300px;
  }

  @media (max-width: 1140px) {
    max-width: 280px;
  }

  @media (max-width: 768px) {
    max-width: 354px;
    margin: 0 auto 14px;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 30px 0 50px;
  display: flex;
  align-items: stretch;

  @media (max-width: 1024px) {
    align-items: flex-start;
    margin: 0;
    height: calc(100% - 54px - 90px);
    overflow: auto;
    padding: 20px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
  }
`;
