import React from "react";
import styled from "styled-components";
import { TextareaType } from "../../types/types";

const Textarea = ({
  label,
  placeholder,
  value,
  readOnly = false,
  onChange,
  max,
  length = true,
  ...props
}: TextareaType) => {
  return (
    <Label>
      {label && <p>{label}</p>}
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
      {length && (
        <Length>
          {value?.length}/{max}
        </Length>
      )}
    </Label>
  );
};

export default Textarea;

const Length = styled.div`
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray5};
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray1};
  padding: 14px;
  border-radius: 10px;
  background: #fafafa;
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }
`;

const Label = styled.label`
  display: block;

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
    margin-bottom: 6px;
  }
`;
