import React from "react";
import styled from "styled-components";

import { ReactComponent as EmptyIcon } from "../../assets/icons/empty-icon.svg";
import { ReactComponent as EmptyIcon2 } from "../../assets/icons/likes-icon.svg";

const Empty = ({
  text,
  type,
}: {
  text: string;
  type?: "feed" | "card" | "challenge" | "badge";
}) => {
  return (
    <EmptyArea
      className={
        (window.location.href.includes("/search") ? "search " : "") + type
      }
    >
      {type === "feed" ? <EmptyIcon2 /> : <EmptyIcon />}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </EmptyArea>
  );
};

export default Empty;

const EmptyArea = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c4c4c4;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;

  & > svg {
    margin-bottom: 10px;
    width: 46px;
    height: 46px;
  }

  &.feed {
    height: 716px;

    & > svg {
      opacity: 0.3;
    }
  }

  &.card {
    height: auto;
    padding: 280px 0;
  }

  &.challenge {
    height: 230px;
  }

  &.badge {
    height: 468px;
  }

  @media (max-width: 1024px) {
    padding: 80px 0;
    height: auto;

    &.search {
      padding: 145px 0;
    }

    &.feed {
      height: auto;
      padding: 145px 0;
    }

    &.card {
      padding: 145px 0;
    }
  }
`;
