import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { ReactComponent as Search } from "../../../assets/icons/search-icon.svg";
import { ReactComponent as Alarm } from "../../../assets/icons/alarm-icon.svg";
import { ReactComponent as AlarmAct } from "../../../assets/icons/alarm-active-icon.svg";

import DefaultLogo from "../../../assets/images/header-logo.svg";

const Header = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [bg, setBg] = useState<string>("#3189FF");
  const [logo, setLogo] = useState<string>();
  const [alarms, setAlarms] = useState<boolean>(false);

  const { data } = useSWR(`/profile`);
  const { data: enterPrice } = useSWR(
    window.localStorage.getItem("MainId") &&
      `/enterprise?url=${window.localStorage.getItem("MainId")}`
  );

  useEffect(() => {
    if (enterPrice) {
      if (enterPrice?.enterpriseColor) setBg(enterPrice?.enterpriseColor);
      if (enterPrice?.enterpriseLogo) setLogo(enterPrice?.enterpriseLogo);
    } else {
      if (window.localStorage.getItem("MainColor")) {
        //@ts-ignore
        setBg(window.localStorage.getItem("MainColor"));
      }

      if (window.localStorage.getItem("MainLogo")) {
        //@ts-ignore
        setLogo(window.localStorage.getItem("MainLogo"));
      }
    }
  }, [enterPrice]);

  useEffect(() => {
    if (!data) return;
    if (data?.alarmCount > 0) {
      setAlarms(true);
    } else {
      setAlarms(false);
    }
  }, [data]);

  return (
    <Wrapper style={{ background: bg }}>
      <Side>
        <Logo
          src={logo ? logo : DefaultLogo}
          alt=""
          onClick={() => {
            navigate("/home");
          }}
        />
      </Side>
      <Side style={{ justifyContent: "flex-end" }}>
        <HeaderButton type="button" onClick={() => navigate(`/search/all`)}>
          <Search />
        </HeaderButton>
        <HeaderButton type="button" onClick={() => navigate(`/alarm`)}>
          {alarms ? <AlarmAct /> : <Alarm />}
        </HeaderButton>
      </Side>
    </Wrapper>
  );
};

export default Header;

const HeaderButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;

const Logo = styled.img`
  width: 100%;
  height: 24px;
  object-fit: contain;
  object-position: left;
  cursor: pointer;
`;

const Side = styled.div`
  min-width: 127px;
  max-width: 127px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;
