import React from "react";
import styled, { css } from "styled-components";
import { Dot } from "../../utils/CommonFunction";
import { RankType } from "../../types/api";
import { useSetRecoilState } from "recoil";
import { userModalState } from "../../utils/atom";

import DefaultProfile from "../../assets/images/proflie-image.svg";
import { useTranslation } from "react-i18next";

const RankList = ({ myRank, item }: { myRank?: boolean; item: RankType }) => {
  const setUserModal = useSetRecoilState(userModalState);
  const { t } = useTranslation("");

  return (
    <Wrapper onClick={() => setUserModal({ id: item?.id })}>
      <Rank>{item?.rank}</Rank>
      <Profile
        src={item.profileImage ? item.profileImage : DefaultProfile}
        alt=""
        myRank={myRank}
      />
      <Text myRank={myRank}>
        <h1>
          {item?.name}
          {myRank && <span>me</span>}
        </h1>
        <p>{item?.department}</p>
      </Text>
      <Numbering myRank={myRank}>
        {Dot(Number(item?.count))} {t("card_count")}
      </Numbering>
    </Wrapper>
  );
};

export default RankList;

const Numbering = styled.div<{ myRank?: boolean }>`
  margin-left: auto;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray7};
  background: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray2};

  ${(props) =>
    props.myRank &&
    css`
      border-radius: 4px;
      border-color: ${(props) => props.theme.color.gray6};
      background: rgba(58, 58, 58, 0.05);
      color: ${(props) => props.theme.color.gray1};
    `}
`;

const Text = styled.div<{ myRank?: boolean }>`
  width: calc(100% - 50px - 100px);

  & > h1 {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};

    & > span {
      margin-left: 4px;
      padding: 2px 5px;
      border-radius: 4px;
      background: ${(props) => props.theme.color.gray7};
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: ${(props) => props.theme.color.gray2};
    }
  }

  & > p {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  ${(props) =>
    props.myRank &&
    css`
      width: calc(100% - 62px - 100px);
    `}

  @media (max-width: 1024px) {
    ${(props) =>
      props.myRank &&
      css`
        width: calc(100% - 50px - 100px);
      `}
  }
`;

const Profile = styled.img<{ myRank?: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.gray7};
  margin-right: 14px;
  object-fit: cover;

  ${(props) =>
    props.myRank &&
    css`
      width: 62px;
      height: 62px;
    `}

  @media (max-width: 1024px) {
    ${(props) =>
      props.myRank &&
      css`
        width: 50px;
        height: 50px;
      `}
  }
`;

const Rank = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  @media (max-width: 1024px) {
    top: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 17px;
  position: relative;
  cursor: pointer;

  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    padding-left: 16px;
  }
`;
