import React from "react";
import styled, { css } from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  size?: "sm" | "md";
  disabled?: boolean;
  styleType?:
    | "line"
    | "line_white"
    | "line_gray"
    | "line_gray_white"
    | "line_white_white"
    | "disable_gray";
};

const Button = ({
  children,
  size = "md",
  disabled,
  styleType,
  ...props
}: ButtonProps) => {
  return (
    <Wrapper className={styleType} size={size} disabled={disabled} {...props}>
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button<{
  size: "sm" | "md";
  disabled?: boolean;
}>`
  //크기 중간
  width: 100%;
  height: 50px;

  //기본
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: white;
  border-radius: 8px;
  background: ${(props) => props.theme.color.sub};
  border: solid 1px ${(props) => props.theme.color.sub};

  //선-회색-흰색
  &.line_gray_white {
    color: white;
    background: none;
    border-color: ${(props) => props.theme.color.gray4};
  }

  //선-흰색-흰
  &.line_white_white {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    border-color: white;
  }

  //선-회색
  &.line_gray {
    color: ${(props) => props.theme.color.gray4};
    background: none;
    border-color: ${(props) => props.theme.color.gray4};
  }

  //선-흰색
  &.line_white {
    color: white;
    background: none;
    border-color: white;
  }

  //선
  &.line {
    color: ${(props) => props.theme.color.gray1};
    background: none;
    border-color: ${(props) => props.theme.color.gray7};
  }

  &:disabled {
    color: white;
    background: ${(props) => props.theme.color.gray6};
    border-color: ${(props) => props.theme.color.gray6};

    &.disable_gray {
      color: ${(props) => props.theme.color.gray5};
      background: ${(props) => props.theme.color.gray7};
      border-color: ${(props) => props.theme.color.gray7};
    }
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      height: 44px;
      font-size: 13px;
    `}
`;
