import React, { useState } from "react";
import styled from "styled-components";
import { SelectType } from "../../types/types";

import { ReactComponent as Icon } from "../../assets/icons/select-icon.svg";

const Select = ({
  label,
  placeholder,
  options,
  value,
  keyValue,
  onChange,
}: SelectType) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Wrapper>
      <p>{label}</p>
      <InputArea className={open ? "active" : ""}>
        <Arrow className="label">
          <Icon />
        </Arrow>
        <StyledSelect
          className={value ? "active" : ""}
          onClick={() => setOpen(!open)}
        >
          {value?.text
            ? value?.text
            : value?.value
            ? value?.value
            : placeholder}
        </StyledSelect>
        <OptionWrapper>
          <OptionList>
            {options.map((option, i) => (
              <Option
                key={keyValue + i}
                className={option.value === value?.value ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(option);
                  setOpen(false);
                }}
              >
                {option?.text ? option?.text : option?.value}
              </Option>
            ))}
          </OptionList>
        </OptionWrapper>
      </InputArea>
    </Wrapper>
  );
};

export default Select;

const Option = styled.li`
  width: 100%;
  height: 34px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;
  border-radius: 6px;

  &.active {
    background: ${(props) => props.theme.color.gray8};
  }
`;

const OptionList = styled.ul`
  list-style-type: none;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray6};
  background: #fff;
  max-height: 216px;
  overflow: auto;
`;

const OptionWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  height: 0px;
  transition: 0.3s;
  z-index: 1;

  .active & {
    max-height: 216px;
    height: fit-content;
  }
`;

const Arrow = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;

  & > svg {
    width: 13px;
    height: 7.8px;
  }
`;

const StyledSelect = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #fafafa;
  padding: 10px 48px 10px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  color: ${(props) => props.theme.color.gray5};

  &.active {
    font-weight: 600;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const InputArea = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  cursor: pointer;
`;

const Wrapper = styled.label`
  width: 100%;

  & > p {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }
`;
