import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Active,
  Alarm,
  Challenge,
  Error,
  Faq,
  Feed,
  Find,
  FindResult,
  Home,
  HomeFeed,
  Mypage,
  PasswordChange,
  Point,
  RankReceive,
  RankSend,
  Receive,
  Root,
  Search,
  Send,
  User,
  VCard,
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import theme from "../utils/theme";
import WrapperRoute from "../components/routes/WrapperRoute";
import HelmetComponent from "../components/HelmetComponent";

const App = () => {
  return (
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WrapperRoute element={<Root />} />} />
            <Route
              path="/:company"
              element={<WrapperRoute element={<Root />} />}
            />
            {/* 비밀번호 찾기 */}
            <Route
              path="/user/find"
              element={<WrapperRoute element={<Find />} />}
            />
            <Route
              path="/user/find/result"
              element={<WrapperRoute element={<FindResult />} />}
            />
            {/* 3개월 비밀번호 변경 */}
            <Route
              path="/user/change"
              element={<WrapperRoute element={<PasswordChange />} />}
            />
            {/* 홈 */}
            <Route path="/home" element={<WrapperRoute element={<Home />} />} />
            <Route
              path="/feed"
              element={<WrapperRoute element={<HomeFeed />} />}
            />
            {/* 자주 묻는 질문 */}
            <Route path="/faq" element={<WrapperRoute element={<Faq />} />} />
            {/* 챌린지 */}
            <Route
              path="/challenge"
              element={<WrapperRoute element={<Challenge />} />}
            />
            {/* vcard */}
            <Route
              path="/card"
              element={<WrapperRoute element={<VCard />} />}
            />
            <Route
              path="/card/receive"
              element={<WrapperRoute element={<Receive />} />}
            />
            <Route
              path="/card/send"
              element={<WrapperRoute element={<Send />} />}
            />
            {/* 랭킹 */}
            <Route
              path="/rank/send"
              element={<WrapperRoute element={<RankSend />} />}
            />
            <Route
              path="/rank/receive"
              element={<WrapperRoute element={<RankReceive />} />}
            />
            {/* 내 프로필 */}
            <Route
              path="/mypage/info"
              element={<WrapperRoute element={<Mypage />} />}
            />
            <Route
              path="/mypage/active"
              element={<WrapperRoute element={<Active />} />}
            />
            <Route
              path="/mypage/point"
              element={<WrapperRoute element={<Point />} />}
            />
            {/* 검색 */}
            <Route
              path="/search/:type"
              element={<WrapperRoute element={<Search />} />}
            />
            <Route
              path="/search/:type/:search"
              element={<WrapperRoute element={<Search />} />}
            />
            {/* 
            <Route
              path="/search/people/:key"
              element={<WrapperRoute element={<User />} />}
            />
            <Route
              path="/search/feed/:key"
              element={<WrapperRoute element={<Feed />} />}
            /> */}
            {/* 알림 */}
            <Route
              path="/alarm"
              element={<WrapperRoute element={<Alarm />} />}
            />

            {/* 404 */}
            <Route path="/*" element={<WrapperRoute element={<Error />} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
