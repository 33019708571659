import React, { useState } from "react";
import styled from "styled-components";
import {
  Dot,
  handleImage,
  handleLogout,
  nFormatter,
} from "../../../utils/CommonFunction";
import ChallengeList from "../../list/ChallengeList";
import RankList from "../../list/RankList";
import QnaBox from "./QnaBox";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { bedgeModalState, twoButtonModalState } from "../../../utils/atom";
import useSWR from "swr";
import { ChallengeType, RankType } from "../../../types/api";

import { ReactComponent as Arrow } from "../../../assets/icons/see-more-icon.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/white-right-arrow-icon.svg";

import DefaultProfile from "../../../assets/images/proflie-image.svg";
import { api } from "../../../utils/api";
import Empty from "../../elements/Empty";
import { useTranslation } from "react-i18next";

const Side = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("");
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setBedgeModal = useSetRecoilState(bedgeModalState);

  const [rankTab, setRankTab] = useState<0 | 1>(0);

  const { data, mutate } = useSWR(
    `/main?page=1&type=${rankTab === 0 ? "send" : "receive"}`
  );

  return (
    <Wrapper>
      <Section>
        <SectionTitle>
          {t("main_profile")}
          <button type="button" onClick={() => navigate(`/mypage/info`)}>
            {t("main_setting")}
            <span>
              <Arrow />
            </span>
          </button>
        </SectionTitle>
        <ProfileArea>
          <Profile
            style={{
              background: `url(${
                data?.user?.profileImage
                  ? handleImage(data?.user?.profileImage)
                  : DefaultProfile
              }) no-repeat center/cover`,
            }}
          >
            <div>
              {data?.user?.badgeImage && (
                <img src={data?.user?.badgeImage} alt="" />
              )}
            </div>
          </Profile>
          <ProfileText>
            <h1>{data?.user?.name}</h1>
            <p>{data?.user?.department}</p>
          </ProfileText>
        </ProfileArea>
        <ProfileContent>{data?.user?.message}</ProfileContent>
        <Seperate />
        <Point onClick={() => navigate(`/mypage/point`)}>
          {t("main_viva")}
          <p>{Dot(data?.user?.availableCompliment)} V</p>
        </Point>
        <Point onClick={() => navigate(`/mypage/point`)}>
          {t("main_point")}
          <p>{Dot(data?.user?.availablePoint)} P</p>
        </Point>
        <Seperate />
        <ValueBox>
          <Value onClick={() => navigate(`/card/send`)}>
            <h1>
              {nFormatter(
                data?.user?.sendVivaCount,
                data?.user?.sendVivaCount > 1000 ? 1 : 0
              )}
            </h1>
            <p> {t("main_send")}</p>
          </Value>
          <Value onClick={() => navigate(`/card/receive`)}>
            <h1>
              {nFormatter(
                data?.user?.receiveVivaCount,
                data?.user?.receiveVivaCount > 1000 ? 1 : 0
              )}
            </h1>
            <p> {t("main_receive")}</p>
          </Value>
          <Value
            onClick={() =>
              setBedgeModal({ select: data?.user?.badgeId, mutate: mutate })
            }
          >
            <h1>
              {nFormatter(
                data?.user?.badgeCount,
                data?.user?.badgeCount > 1000 ? 1 : 0
              )}
            </h1>
            <p>{t("main_bedge")}</p>
          </Value>
        </ValueBox>
      </Section>
      <Section>
        <SectionTitle>
          {t("main_challenge")}
          <button type="button" onClick={() => navigate(`/challenge`)}>
            {t("main_more")}
            <span>
              <Arrow />
            </span>
          </button>
        </SectionTitle>
        <ChallengeArea>
          {data?.challenges ? (
            data?.challenges?.map((item: ChallengeType, i: number) => (
              <ChallengeList
                key={"challenge_list_" + i}
                item={item}
                mutate={mutate}
              />
            ))
          ) : (
            <Empty type="challenge" text={`${t("main_challenge_none")}`} />
          )}
        </ChallengeArea>
      </Section>
      <Section>
        <SectionTitle>
          {t("main_rank")}
          <button type="button" onClick={() => navigate(`/rank/send`)}>
            {t("main_more")}
            <span>
              <Arrow />
            </span>
          </button>
        </SectionTitle>
        <TabRank>
          <button
            type="button"
            className={rankTab === 0 ? "active" : ""}
            onClick={() => setRankTab(0)}
          >
            {t("main_sendRk")}
          </button>
          <button
            type="button"
            className={rankTab === 1 ? "active" : ""}
            onClick={() => setRankTab(1)}
          >
            {t("main_receiveRk")}
          </button>
        </TabRank>
        {data?.my_vranking && (
          <RankList myRank={true} item={data?.my_vranking} />
        )}
        <Seperate />
        <div>
          {data?.vrankings.map(
            (item: RankType, i: number) =>
              i < 5 && <RankList key={"rank_list_" + i} item={item} />
          )}
        </div>
      </Section>
      <Section>
        <QnaBox />
        <Guide>
          {t("main_guide")}
          <RightArrow />
        </Guide>
        <Logout
          type="button"
          onClick={() =>
            setTwoButtonModal({
              title: `${t("main_logout")}`,
              text: `${t("mypage_logout")}`,
              confirmText: `${t("main_logout")}`,
              cancleText: `${t("post_cancle")}`,
              onConfirm: () => handleLogout(),
            })
          }
        >
          {t("main_logout")}
        </Logout>
      </Section>
    </Wrapper>
  );
};

export default Side;

const Logout = styled.button`
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-decoration: underline;
  color: ${(props) => props.theme.color.gray3};
  margin: auto;
  display: block;
`;

const Guide = styled.div`
  cursor: pointer;
  padding: 15px 16px;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.color.sub};
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.sub};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 40px;

  & > svg {
    width: 6px;
    height: 10px;

    & path {
      fill: ${(props) => props.theme.color.sub};
    }
  }
`;

const TabRank = styled.div`
  margin: 30px 0 20px;
  display: flex;
  align-items: center;

  & > button {
    margin-right: 8px;
    padding: 8px 12px;
    border-radius: 38px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    background: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray5};

    &.active {
      background: ${(props) => props.theme.color.gray1};
      border-color: ${(props) => props.theme.color.gray1};
      color: white;
    }
  }
`;

const ChallengeArea = styled.div`
  margin-top: 30px;
`;

const Value = styled.div`
  max-width: 81px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  & > h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  &:nth-of-type(n + 2) {
    margin-left: 30px;
  }
`;

const ValueBox = styled.div`
  max-width: 303px;
  width: 100%;
  margin: auto;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-top: 20px;
  cursor: pointer;

  & > p {
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const Seperate = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.gray7};
`;

const ProfileContent = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => props.theme.color.gray3};
`;

const ProfileText = styled.div`
  width: calc(100% - 85px);

  & > h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
    margin-top: 6px;
  }
`;

const Profile = styled.div`
  position: relative;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-right: 23px;

  & > div {
    position: absolute;
    bottom: 0;
    right: -3px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const ProfileArea = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray4};

    & > span {
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        width: 6px;
        height: 10px;
      }
    }
  }
`;

const Section = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 70px;
  }
`;

const Wrapper = styled.div`
  max-width: 430px;
  width: 100%;
  padding: 40px 40px 60px;
  background: rgba(30, 30, 30, 0.05);

  @media (max-width: 1400px) {
    max-width: 400px;
  }

  @media (max-width: 1200px) {
    max-width: 360px;
    padding: 20px 20px 60px;
  }
`;
