import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { completeModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";

import { ReactComponent as Challenge } from "../../assets/images/challenge.svg";

const CompleteModal = () => {
  const [modal, setModal] = useRecoilState(completeModalState);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Challenge />
        <Title>챌린지 달성 완료</Title>
        <Text>
          [{modal?.title}]
          <br />
          달성을 완료했습니다. 축하합니다!
        </Text>
        <Button onClick={handleClose}>확인</Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CompleteModal;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  margin-bottom: 40px;
`;

const Title = styled.div`
  margin: 20px 0 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.27;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;

  @media (max-width: 1024px) {
    margin: 28px 0 10px;
    font-size: 20px;
    line-height: 20px;
  }
`;

const Wrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 50px 30px 30px;
  background: white;
  border-radius: 20px;

  & > svg {
    width: 96px;
    height: 96px;
    display: block;
    margin: auto;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px 20px;

    & > svg {
      width: 80px;
      height: 80px;
    }
  }
`;
