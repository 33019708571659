import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Dayjs } from "dayjs";

const TimePickerInput = ({
  label,
  date,
  setDate,
  format = "YYYY.MM.DD",
  max = false,
}: {
  label?: string;
  date: Dayjs;
  setDate: (value: Dayjs) => void;
  format?: string;
  max?: boolean;
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Picker>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              value={date}
              //@ts-ignore
              onChange={(newValue: Dayjs) => setDate(newValue)}
              views={["year", "month", "day", "hours", "minutes"]}
              format="YYYY.MM.DD HH:mm"
              closeOnSelect
              disablePast
            />
          </DemoContainer>
        </LocalizationProvider>
      </Picker>
    </Wrapper>
  );
};

export default TimePickerInput;

const Picker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #fafafa;
  cursor: pointer;

  & > div {
    padding-top: 0;
    width: 100%;

    & fieldset {
      border: 0;
    }

    & input {
      height: 44px;
      box-sizing: border-box;
      padding: 0 14px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray1};
    }
  }
`;

const Label = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  margin-bottom: 6px;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
