import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SortState } from "../../../utils/atom";

import { useTranslation } from "react-i18next";

const CardSide = () => {
  const { t } = useTranslation("");

  const [modal, setModal] = useRecoilState(SortState);

  useEffect(() => {
    if (modal === null) setModal({ type: "recent" });
  }, [modal]);

  return (
    <Wrapper>
      <SectionTitle>{t("feed_card")}</SectionTitle>
      <Section>
        <h1>카테고리</h1>
        <CheckBoxsArea></CheckBoxsArea>
      </Section>
      <Section>
        <h1>정렬 순서</h1>
        <Sort
          className={modal?.type === "recent" ? "active" : ""}
          onClick={() => setModal({ type: "recent" })}
        >
          최신순
        </Sort>
        <Sort
          className={modal?.type === "popular" ? "active" : ""}
          onClick={() => setModal({ type: "popular" })}
        >
          인기순
        </Sort>
      </Section>
    </Wrapper>
  );
};

export default CardSide;

const CheckBoxsArea = styled.div``;

const Sort = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
  color: #3a3a3a;
  opacity: 0.3;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
`;

const SectionTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
  color: #3a3a3a;
`;

const Section = styled.div`
  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #3a3a3a;
    padding-bottom: 20px;
    border-bottom: solid 1px ${(props) => props.theme.color.gray7};
    margin-bottom: 20px;
  }

  &:nth-of-type(n + 2) {
    margin-top: 40px;
  }
`;

const Wrapper = styled.div`
  max-width: 430px;
  width: 100%;
  padding: 40px 40px 60px;
  background: rgba(30, 30, 30, 0.05);

  @media (max-width: 1400px) {
    max-width: 400px;
  }

  @media (max-width: 1200px) {
    max-width: 360px;
    padding: 20px 20px 60px;
  }
`;
