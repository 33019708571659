import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import useSWR from "swr";

import { ReactComponent as Nav2 } from "../../../assets/icons/nav2-icon.svg";
import { ReactComponent as Nav3 } from "../../../assets/icons/nav3-icon.svg";
import { ReactComponent as Nav4 } from "../../../assets/icons/nav4-icon.svg";
import { ReactComponent as NavKor } from "../../../assets/icons/navkor-icon.svg";
import { ReactComponent as NavEng } from "../../../assets/icons/naveng-icon.svg";

import { ReactComponent as Search } from "../../../assets/icons/search-icon.svg";
import { ReactComponent as Alarm } from "../../../assets/icons/alarm-icon.svg";
import { ReactComponent as AlarmAct } from "../../../assets/icons/alarm-active-icon.svg";

import DefaultLogo from "../../../assets/images/header-logo.svg";
import i18n from "../../../locales/i18n";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  alarm?: number;
}
const Header = ({ alarm }: HeaderProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("");

  const [bg, setBg] = useState<string>("#3189FF");
  const [logo, setLogo] = useState<string>();
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [alarms, setAlarms] = useState<boolean>(false);
  const [languageOpen, setLanguageOpen] = useState<boolean>(false);
  const [language, setLanguage] = useState("korean");

  const { data: enterPrice } = useSWR(
    window.localStorage.getItem("MainId") &&
      `/enterprise?url=${window.localStorage.getItem("MainId")}`
  );
  useEffect(() => {
    if (!alarm) return;
    if (alarm > 0) {
      setAlarms(true);
    } else {
      setAlarms(false);
    }
  }, [alarm]);

  useEffect(() => {
    if (enterPrice) {
      if (enterPrice?.enterpriseColor) setBg(enterPrice?.enterpriseColor);
      if (enterPrice?.enterpriseLogo) setLogo(enterPrice?.enterpriseLogo);
    } else {
      if (window.localStorage.getItem("MainColor")) {
        //@ts-ignore
        setBg(window.localStorage.getItem("MainColor"));
      }

      if (window.localStorage.getItem("MainLogo")) {
        //@ts-ignore
        setLogo(window.localStorage.getItem("MainLogo"));
      }
    }
  }, [enterPrice]);

  useEffect(() => {
    if (params?.key) setSearch(params?.key);
  }, []);

  return (
    <Wrapper style={{ background: bg }}>
      <Side>
        <Logo
          src={logo ? logo : DefaultLogo}
          alt=""
          onClick={() => {
            navigate("/home");
          }}
        />
      </Side>
      <Center>
        <HeaderNav to={`/feed`} color={bg}>
          <Nav2 />
        </HeaderNav>
        <HeaderNav to={`/card`} color={bg}>
          <Nav3 />
        </HeaderNav>
        <HeaderNavBtn
          color={bg}
          onClick={() => {
            window.open("http://www.naver.com");
          }}
        >
          <Nav4 />
        </HeaderNavBtn>
        <HeaderNav to={`/alarm`} color={bg}>
          {alarms ? <AlarmAct /> : <Alarm />}
        </HeaderNav>
      </Center>
      <Side style={{ justifyContent: "flex-end" }}>
        <HeaderButton
          type="button"
          style={
            open || window.location.href.includes("/search")
              ? {
                  width: "auto",
                  padding: "0 13px 0 20px",
                }
              : {}
          }
        >
          {(open || window.location.href.includes("/search")) && (
            <SearchInput
              type="text"
              placeholder={`${t("alarm_search")}`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.code.toLowerCase() === "enter" || e.charCode === 13) {
                  search !== "" && navigate(`/search/all/${search}`);
                }
              }}
            />
          )}
          <Search
            onClick={() => {
              if (open) {
                search !== "" && navigate(`/search/all/${search}`);
              } else setOpen(true);
            }}
          />
        </HeaderButton>
        <HeaderButton type="button" onClick={() => setLanguageOpen(true)}>
          {language === "korean" ? <NavKor /> : <NavEng />}
        </HeaderButton>
        {languageOpen && (
          <LanguageBox>
            <div
              onClick={() => {
                setLanguageOpen(false);
                i18n.changeLanguage("ko");
                setLanguage("korean");
              }}
            >
              <NavKor />
              <p>{t("header_korean")}</p>
            </div>
            <div
              onClick={() => {
                setLanguageOpen(false);
                i18n.changeLanguage("en");
                setLanguage("english");
              }}
            >
              <NavEng />
              <p>{t("header_english")}</p>
            </div>
          </LanguageBox>
        )}
      </Side>
    </Wrapper>
  );
};

export default Header;

const LanguageBox = styled.div`
  position: absolute;
  right: 0;
  top: 86px;
  width: 245px;
  padding: 6px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid rgba(196, 196, 196, 0.2);
  z-index: 1;
  & div {
    display: flex;
    align-items: center;
    padding: 10px 14px;
  }
  & div:hover {
    background: #f6f6f6;
  }
  & p {
    font-weight: 400;
    font-size: 15px;
    line-height: 1;
    color: #3a3a3a;
    margin-left: 10px;
  }
`;
const SearchInput = styled.input`
  width: 240px;
  margin-right: 20px;
  background: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray8};

  &::placeholder {
    color: ${(props) => props.theme.color.gray7};
  }

  @media (max-width: 1280px) {
    width: 120px;
  }
`;

const HeaderButton = styled.button`
  width: 52px;
  height: 52px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const HeaderNavBtn = styled.button<{ color: string }>`
  width: 52px;
  height: 52px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;

  &.active {
    background: white;
  }

  ${(props) =>
    props.color &&
    css`
      &.active > svg {
        & path {
          fill-opacity: 1;
          fill: ${props.color};
        }
      }
    `}
`;

const HeaderNav = styled(NavLink)<{ color: string }>`
  width: 52px;
  height: 52px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(n + 2) {
    margin-left: 26px;
  }

  &.active {
    background: white;
  }

  ${(props) =>
    props.color &&
    css`
      &.active > svg {
        & path {
          fill-opacity: 1;
          fill: ${props.color};
        }
      }
    `}
`;

const Logo = styled.img`
  width: 100%;
  height: 52px;
  object-fit: contain;
  object-position: left;
  cursor: pointer;
`;

const Center = styled.div`
  padding: 0 26px;
  display: flex;
  align-items: center;
`;

const Side = styled.div`
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  height: 80px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
