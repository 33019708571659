import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FilterSelectType } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { commentMenuModalState, twoButtonModalState } from "../../utils/atom";
import { api } from "../../utils/api";

import { ReactComponent as Menu } from "../../assets/icons/side-menu-icon.svg";

const CommentMenuButton = ({
  id,
  mutate,
  setEdit,
}: {
  id: number;
  mutate: () => void;
  setEdit: (value: boolean) => void;
}) => {
  const navigate = useNavigate();

  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setMenuModal = useSetRecoilState(commentMenuModalState);

  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = async () => {
    const {
      data: { success, message, data },
    } = await api.delete(`/comment/delete?commentId=${id}`);

    if (success) {
      mutate();
    } else {
      window.alert(message);
    }
  };

  return (
    <Wrapper>
      <div>
        <Button
          type="button"
          onClick={() => {
            if (window.outerWidth > 1024) setOpen(!open);
            else
              setMenuModal({
                handleEdit: () => setEdit(true),
                handleDelete: () =>
                  setTwoButtonModal({
                    title: "댓글 삭제",
                    text: "댓글를 삭제하시겠습니까?<br/>삭제된 댓글는 복구하실 수 없습니다.",
                    confirmText: "삭제",
                    cancleText: "취소",
                    onConfirm: handleDelete,
                  }),
              });
          }}
        >
          <Menu />
        </Button>
        <OptionWrapper className={open ? "active" : ""}>
          <OptionList>
            <Option
              onClick={() => {
                setOpen(false);
                setEdit(true);
              }}
            >
              댓글 수정
            </Option>
            <Option
              onClick={() => {
                setOpen(false);
                setTwoButtonModal({
                  title: "댓글 삭제",
                  text: "댓글를 삭제하시겠습니까?<br/>삭제된 댓글는 복구하실 수 없습니다.",
                  confirmText: "삭제",
                  cancleText: "취소",
                  onConfirm: handleDelete,
                });
              }}
            >
              댓글 삭제
            </Option>
          </OptionList>
        </OptionWrapper>
      </div>
    </Wrapper>
  );
};

export default CommentMenuButton;

const Option = styled.li`
  width: 100%;
  height: 34px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;
`;

const OptionList = styled.ul`
  list-style-type: none;
  padding: 6px 0;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray6};
  background: #fff;
  max-height: 216px;
`;

const OptionWrapper = styled.div`
  position: absolute;
  top: 34px;
  right: 0;
  width: 120px;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s;
  z-index: 1;

  &.active {
    max-height: 216px;
    overflow: auto;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 4px;

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    width: 24px;
    height: 24px;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;

  & > div {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    & > div {
      width: 24px;
      height: 24px;
    }
  }
`;
