import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";

interface ModalWrapperProps {
  children: ReactNode | ReactNode[];
  position?: "bottom" | "full" | "top";
  onClose?: () => void;
}

function ModalWrapper({ children, position, onClose }: ModalWrapperProps) {
  const handleClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (e.currentTarget !== e.target) return;

    onClose?.();
  };

  useEffect(() => {
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, []);

  return (
    <Wrapper onClick={handleClose} className={position ? position : ""}>
      <ScrollBox>
        {position === "bottom" && <Close onClick={handleClose} />}
        {children}
      </ScrollBox>
    </Wrapper>
  );
}

const Close = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ScrollBox = styled.div`
  max-width: 980px;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  padding: 120px 20px;

  & > div {
    margin: auto;
  }

  @media (max-width: 1024px) {
    .full &,
    .bottom & {
      max-width: 100%;
      height: 100%;
      padding: 0;
      overflow: hidden;
    }

    .bottom & {
      position: relative;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.6);
  z-index: 10;
  overflow: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  &.top {
    z-index: 11;
  }

  @media (max-width: 1024px) {
    &.full {
      display: block;
    }

    &.bottom {
      align-items: flex-end;
    }
  }
`;

export default ModalWrapper;
