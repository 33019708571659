import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, challengeModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import Textarea from "../elements/Textarea";
import ImageUpload from "../elements/ImageUpload";
import Button from "../elements/Button";
import { api } from "../../utils/api";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const ChallengePostModal = () => {
  const [modal, setModal] = useRecoilState(challengeModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const [content, setContent] = useState<string>("");
  const [image, setImage] = useState<File>();

  const { data } = useSWR(
    modal !== null && `/challenge/detail?challengeId=${modal?.id}`
  );

  const handleClose = () => {
    setModal(null);
    setContent("");
    setImage(undefined);
  };

  const handleSubmit = async (e: any) => {
    e.target.disabled = true;

    const formData = new FormData();
    formData.append("challengeId", String(modal?.id));
    formData.append("contents", content);
    if (image) formData.append("file", image);

    const {
      data: { success, message, data },
    } = await api.post("/challenge/complete", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
      setAlertModal({
        title: "챌린지 인증 신청 완료",
        text: "챌린지 인증 신청을 완료했습니다.<br/>관리자 검토 후 리워드 부여 예정입니다.",
      });
    } else {
      window.alert(message);
      e.target.disabled = false;
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          챌린지 달성 인증
          <Close onClick={handleClose} />
        </Title>
        <Challenge>
          <img src={data?.challenge?.image} />
          <TitleText
            dangerouslySetInnerHTML={{
              __html: `<b>${data?.challenge?.title}</b><br />${
                data?.challenge?.type === "auto"
                  ? "자동 달성 챌린지"
                  : "참가형 챌린지"
              }`,
            }}
          />
        </Challenge>
        <Content>
          <Challenge className="mobile">
            <img src={data?.challenge?.image} />
            <TitleText>
              <b>{data?.challenge?.title}</b>
              <br />
              {data?.challenge?.type === "auto"
                ? "자동 달성 챌린지"
                : "참가형 챌린지"}
            </TitleText>
          </Challenge>
          <Textarea
            placeholder={`해당 챌린지를 달성했다는 것을 알릴 수 있는 내용을 구체적으로 입력해 주세요.`}
            value={content}
            onChange={(e) => setContent(e.target.value.slice(0, 300))}
            max={300}
          />
          <ImageUpload
            label="파일 업로드"
            placeholder="파일 첨부 (최대 1개)"
            image={image ? image?.name : ""}
            onChange={(file) => setImage(file)}
          />
        </Content>
        <MobileButtonBox>
          <Button
            styleType="disable_gray"
            onClick={handleSubmit}
            disabled={content === ""}
          >
            인증 신청하기
          </Button>
        </MobileButtonBox>
        <Button
          styleType="disable_gray"
          onClick={handleSubmit}
          disabled={content === ""}
        >
          인증 신청하기
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ChallengePostModal;

const MobileButtonBox = styled.div`
  padding: 20px;
  background: white;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 30px 0 50px;

  & > label:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
    width: 100%;
    height: calc(100% - 54px - 90px);
    overflow: auto;
    margin: 0;
  }
`;

const TitleText = styled.div`
  width: 100%;
  margin-left: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray1};

  & > b {
    font-weight: 700;
    margin-bottom: 4px;
  }

  @media (max-width: 1024px) {
    margin-left: 10px;
    font-size: 13px;

    & > b {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
`;

const Challenge = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  & > img {
    width: 52px;
    min-width: 52px;
    height: 52px;
    border-radius: 50px;
    object-fit: cover;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none;

    &.mobile {
      display: flex;
      margin: 0 0 24px;

      & > img {
        width: 43px;
        min-width: 43px;
        height: 43px;
      }
    }
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    height: 54px;
    justify-content: center;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  & > button {
    display: block;
    max-width: 360px;
    margin: auto;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;

    & > button {
      display: none;
    }
  }
`;
