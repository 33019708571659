import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import useSWR from "swr";

import { ReactComponent as Nav1 } from "../../../assets/icons/nav1-icon.svg";
import { ReactComponent as Nav2 } from "../../../assets/icons/nav2-icon.svg";
import { ReactComponent as Nav3 } from "../../../assets/icons/nav3-icon.svg";
import { ReactComponent as Nav4 } from "../../../assets/icons/nav4-icon.svg";

import DefaultProfile from "../../../assets/images/proflie-image.svg";

const GNB = () => {
  const params = useParams();

  const [bg, setBg] = useState<string>("#3189FF");

  const { data } = useSWR(`/profile`);
  const { data: enterPrice } = useSWR(
    window.localStorage.getItem("MainId") &&
      `/enterprise?url=${window.localStorage.getItem("MainId")}`
  );

  useEffect(() => {
    if (enterPrice) {
      if (enterPrice?.enterpriseColor) setBg(enterPrice?.enterpriseColor);
    } else {
      if (window.localStorage.getItem("MainColor")) {
        //@ts-ignore
        setBg(window.localStorage.getItem("MainColor"));
      }
    }
  }, [enterPrice]);

  return (
    <Wrapper>
      <HeaderNav to={`/home`} color={bg}>
        <Nav1 />홈
      </HeaderNav>
      <HeaderNav to={`/feed`} color={bg}>
        <Nav2 />
        피드
      </HeaderNav>
      <HeaderNav to={`/card`} color={bg}>
        <Nav3 />
        V-CARD
      </HeaderNav>
      <HeaderNavBtn
        color={bg}
        onClick={() => {
          window.open("http://www.naver.com");
        }}
      >
        <Nav4 />
        V-SHOP
      </HeaderNavBtn>
      <HeaderNav to={`/mypage/info`} color={bg}>
        <Profile
          src={data?.profileUrl ? data?.profileUrl : DefaultProfile}
          alt=""
        />
        마이페이지
      </HeaderNav>
    </Wrapper>
  );
};

export default GNB;

const Profile = styled.img`
  border-radius: 24px;
  border: 1px solid ${(props) => props.theme.color.gray8};
  width: 24px;
  height: 24px;
  object-fit: cover;
  display: block;
  margin: 0 auto 5px;
`;

const HeaderNavBtn = styled.button<{ color: string }>`
  height: 60px;
  padding: 10px 20px;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray5_sub};
  text-align: center;
  white-space: nowrap;

  & > svg {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto 5px;

    & path {
      fill-opacity: 1;
      fill: ${(props) => props.theme.color.gray7};
    }
  }

  &.active {
    color: ${(props) => props.theme.color.gray1};
  }

  ${(props) =>
    props.color &&
    css`
      &.active > svg {
        & path {
          fill-opacity: 1;
          fill: ${props.color};
        }
      }
    `}
`;

const HeaderNav = styled(NavLink)<{ color: string }>`
  height: 60px;
  padding: 10px 20px;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray5_sub};
  text-align: center;
  white-space: nowrap;

  & > svg {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto 5px;

    & path {
      fill-opacity: 1;
      fill: ${(props) => props.theme.color.gray7};
    }
  }

  &.active {
    color: ${(props) => props.theme.color.gray1};
  }

  ${(props) =>
    props.color &&
    css`
      &.active > svg {
        & path {
          fill-opacity: 1;
          fill: ${props.color};
        }
      }
    `}
`;

const Wrapper = styled.div`
  position: fixed;
  height: 61px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${(props) => props.theme.color.gray7};
  background: white;
  z-index: 2;
`;
