const color = {
  sub: "#3189FF",
  red: "#EB5757",
  blue: "#2F80ED",

  gray1: "#1E1E1E",
  gray2: "#3C3B4B",
  gray3: "#5D5D5D",
  gray4: "#7C7C7C",
  gray5_sub: "#CDCDD6",
  gray5: "#9F9F9F",
  gray6: "#C4C4C4",
  gray7: "#E2E2E2",
  gray8: "#f6f6f6",
};

export type ColorType = typeof color;

export default { color };
