import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChildrenType } from "../../types/types";
import MainWrapper from "./pc/MainWrapper";
import UserWrapper from "./pc/UserWrapper";
import { useParams } from "react-router-dom";

const PcWrapper = ({ children }: ChildrenType) => {
  const params = useParams();

  return (
    <>
      {window.location.pathname.includes(`${params?.company}`) ||
      window.location.pathname === `/` ? (
        <Wrapper>{children}</Wrapper>
      ) : window.location.pathname.includes("/user") ? (
        <Wrapper>
          <UserWrapper>{children}</UserWrapper>
        </Wrapper>
      ) : (
        <Wrapper>
          <MainWrapper>{children}</MainWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default PcWrapper;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;
