import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { OptionType, UserTagSelectType } from "../../types/types";
import useSWR from "swr";

import { ReactComponent as Icon } from "../../assets/icons/user-tag-icon.svg";

const UserTagSelect = ({
  label,
  placeholder,
  value,
  onChange,
  handleClick,
}: UserTagSelectType) => {
  const inputRef = useRef(null);

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<OptionType[]>([]);

  const { data } = useSWR(`/user?keyword=${search}`);
  const { data: profile } = useSWR("/profile");

  useEffect(() => {
    if (value)
      //@ts-ignore
      setSearch(value?.text ? value?.text : value?.value);
  }, [value]);

  useEffect(() => {
    if (data) {
      let arr: OptionType[] = [];

      for (const user of data?.users) {
        arr.push({
          value: user?.id,
          text: `${user?.name} ${user?.department}`,
        });
      }

      setOptions(arr);
    }
  }, [data]);

  return (
    <Wrapper>
      {label && <p>{label}</p>}
      <InputArea className={open ? "active" : ""}>
        <Arrow className="label">
          <Icon />
        </Arrow>
        <StyledSelect
          ref={inputRef}
          placeholder={placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setOpen(true)}
        />
        <OptionWrapper>
          <OptionList>
            {open &&
              options.map(
                (option, i) =>
                  profile?.id !== option?.value && (
                    <Option
                      key={"user_tag_option" + i}
                      className={option.value === value?.value ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick && handleClick();
                        let input: any = inputRef.current;
                        onChange(option);
                        setOpen(false);
                        input.blur();
                      }}
                    >
                      {option?.text ? option?.text : option?.value}
                    </Option>
                  )
              )}
          </OptionList>
        </OptionWrapper>
      </InputArea>
    </Wrapper>
  );
};

export default UserTagSelect;

const Option = styled.li`
  width: 100%;
  height: 34px;
  padding: 0 14px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.color.gray2};
  display: flex;
  align-items: center;

  &.active {
    background: ${(props) => props.theme.color.gray8};
  }
`;

const OptionList = styled.ul`
  list-style-type: none;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray6};
  background: #fff;
  max-height: 216px;
  overflow: auto;
`;

const OptionWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  height: 0px;
  transition: 0.3s;
  z-index: 1;

  .active & {
    max-height: 216px;
    height: fit-content;
  }
`;

const Arrow = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledSelect = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px 48px 10px 14px;
  border-radius: 10px;
  background: #fafafa;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  color: ${(props) => props.theme.color.gray1};

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }
`;

const InputArea = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  cursor: pointer;
`;

const Wrapper = styled.label`
  width: 100%;

  & > p {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }
`;
