import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { termModalState } from "../../../utils/atom";
import useSWR from "swr";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const setTermModal = useSetRecoilState(termModalState);
  const { t } = useTranslation("");

  const { data } = useSWR(`/profile`);

  return (
    <>
      <InfoArea>
        <h1>{t("footer_vivalounge")}</h1>
        <Info>
          <b>{t("footer_ceo")}</b>&nbsp;<span>|</span>
          &nbsp;홍길동&nbsp;&nbsp;<b>{t("footer_addr")}</b>
          &nbsp;<span>|</span>
          &nbsp;서울특별시&nbsp;성동구&nbsp;안암로&nbsp;3길&nbsp;51,&nbsp;4-6층&nbsp;&nbsp;
          <b>{t("footer_company")}</b>&nbsp;
          <span>|</span>&nbsp;545-86-01374
          <br />
          <b>{t("footer_sale")}</b>&nbsp;<span>|</span>
          &nbsp;2023-서울중구-0350호&nbsp;&nbsp;<b>{t("footer_center")}</b>
          &nbsp;
          <span>|</span>
          &nbsp;1599-9999&nbsp;&nbsp;<b>{t("footer_email")}</b>&nbsp;
          <span>|</span>
          &nbsp;support@viva.com
        </Info>
        <Term>
          <button
            type="button"
            onClick={() => setTermModal({ type: "service" })}
          >
            {t("footer_service")}
          </button>
          <span />
          <button
            type="button"
            onClick={() => setTermModal({ type: "privacy" })}
          >
            {t("footer_private")}
          </button>
          {data && data?.isAdmin === 1 && (
            <>
              <span />
              <button
                type="button"
                onClick={() =>
                  window.open("https://enterprise.vivalounge.fifteenh.io/login")
                }
              >
                {t("footer_manager")}
              </button>
            </>
          )}
        </Term>
      </InfoArea>
      <Copyright>COPYRIGHT © 법인명. All right reserved</Copyright>
    </>
  );
};

export default Footer;

const Copyright = styled.div`
  width: 100%;
  padding: 20px 40px;
  background: ${(props) => props.theme.color.gray8};
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${(props) => props.theme.color.gray5_sub};
`;

const Term = styled.div`
  display: flex;
  align-items: center;

  & > button {
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    color: ${(props) => props.theme.color.gray4};
  }

  & > span {
    display: block;
    width: 1px;
    height: 11px;
    margin: 0 16px;
    background: #d9d9d9;
  }
`;

const Info = styled.div`
  margin: 18px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.052px;
  color: ${(props) => props.theme.color.gray3};
  opacity: 0.8;

  & > span {
    color: ${(props) => props.theme.color.gray7};
  }

  & > b {
    font-weight: 700;
    opacity: 1;
  }
`;

const InfoArea = styled.div`
  padding: 40px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.gray7};
  background: ${(props) => props.theme.color.gray8};

  & > h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: ${(props) => props.theme.color.gray3};
  }
`;
